import axios from "axios/index";

export default {
  state: {
    company: null,
    farm: null,
    soil_types: null,
  },
  getters: {
    company: state => state.company,
    farm: state => state.farm,
    accountIsSetup: (state) => {
      if(!state.company) {
        // console.log("no company");
        return false;
      } else if (!state.farm) {
        // console.log("no farm");
        return false;
      }
      return true;
    },
    soil_types: state => state.soil_types,
  },
  mutations: {
    SET_SOIL_TYPES(state, soil_types) {
      state.soil_types = soil_types;
    },
    RESET(state) {
      state.company = null;
      state.farm = null;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_FARM(state, farm) {
      state.farm = farm;
    },
  },
  actions: {
    retrieveAccountInformation({ commit }) {

      commit('SET_FIELDS', [], { root: true });
      commit('SET_NUTRIENTS', [], { root: true });
      commit('SET_TEST_TYPES', [], { root: true });
      commit('SET_CROPS', [], { root: true });
      commit('SET_FERTILIZERS', [], { root: true });
      commit('SET_SOIL_TYPES', [], { root: true });
      return new Promise((resolve, reject) => {
        axios
        .get("/account-information")
        .then(response => {
          console.log(response.data);
          commit('ADD_FIELDS', response.data.fields, { root: true });
          commit('RETRIEVE_NUTRIENTS', response.data.nutrients, { root: true });
          commit('RETRIEVE_TEST_TYPES', response.data.test_types, { root: true });
          commit('ADD_CROPS', response.data.crops, { root: true });
          commit('ADD_FERTILIZERS', response.data.fertilizers, { root: true });
          commit('SET_SOIL_TYPES', response.data.soil_types);
        }).catch(error => {
          reject(error);
        });
      });
    },
    storeCompany({ commit }, companyName) {
      return new Promise((resolve, reject) => {
        axios
        .post("/company", {
          name: companyName,
        })
        .then(response => {
          commit("SET_COMPANY", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      });
    },
    updateCompany({ commit, state }, companyName) {
      return new Promise((resolve, reject) => {
        axios
          .patch("/company/" + state.company.id, {
            name: companyName,
            user_id: state.company.user_id
          })
          .then(response => {
            commit("SET_COMPANY", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeFarm({ commit }, farmName) {
      return new Promise((resolve, reject) => {
        axios
        .post("/farm", {
          name: farmName,
        })
        .then(response => {
          commit("SET_FARM", response.data);
          console.log(response);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      });
    },
    retrieveCompany({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/company")
          .then(response => {
            commit("SET_COMPANY", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    retrieveSetup({ commit, rootState }) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .get("/setup")
          .then(response => {
            commit("SET_COMPANY", response.data.company);
            commit("SET_FARM", response.data.farm);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    retrieveFarm({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/farm")
          .then(response => {
            commit("SET_COMPANY", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    }
  }
};
