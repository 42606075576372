<template lang="html">
  <div class="drawer">
    <section>

      <h3 v-if="setting=='new'">New Fertilizer<img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'fertilizer_form')"></h3>
      <h3 v-if="setting=='edit'">Edit Fertilizer<img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'fertilizer_form')"></h3>

      <ul class="dynamic__form">
        <li>
          <label for="">Name:</label>
          <input type="text" v-model="form.name" class="with__prefix"
          :class="{ error: errors.name }"
          @blur="[$store.commit('REMOVE_ERROR', 'name'), $forceUpdate()]">
          <span class="prefix">Custom</span>
          <div class="validation__error" v-if="errors.name">{{ errors.name[0] }}</div>
        </li>
        <li>
          <label for="">% N:</label>
          <input type="number" v-model="form.N" max="100" placeholder="Max: 100"
          :class="{ error: errors.N }"
          @blur="[$store.commit('REMOVE_ERROR', 'N'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.N">{{ errors.N[0] }}</div>
        </li>
        <li>
          <label for="">% P:</label>
          <input type="number" v-model="form.P" max="100" placeholder="Max: 100"
          :class="{ error: errors.P }"
          @blur="[$store.commit('REMOVE_ERROR', 'P'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.P">{{ errors.P[0] }}</div>
        </li>
        <li>
          <label for="">% K:</label>
          <input type="number" v-model="form.K" max="100" placeholder="Max: 100"
          :class="{ error: errors.K }"
          @blur="[$store.commit('REMOVE_ERROR', 'K'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.K">{{ errors.K[0] }}</div>
        </li>
        <li>
          <label for="">% S:</label>
          <input type="number" v-model="form.S" max="100" placeholder="Max: 100"
          :class="{ error: errors.S }"
           @blur="[$store.commit('REMOVE_ERROR', 'S'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.S">{{ errors.S[0] }}</div>
        </li>
      </ul>

      <ul class="option__group" v-if="setting == 'edit'">
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeForm">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitEditFertilizer">Edit</button>
      </ul>

      <ul class="option__group" v-else>
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeForm">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitNewFertilizer">Create New</button>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  created(){
    this.$store.commit("RESET_ERRORS");

    // overwrite the form with data if provided
    if(this.data != undefined) {
      this.setting = 'edit';
      this.form = this.data;
    }
  },
  props: {
    data: '',
  },
  data() {
    return {
      // Standard values of a fertilizer.
      form: {
        company_id: null,
        name: '',
        N: '',
        P: '',
        K: '',
        S: '',
      },
      setting: 'new',
      buttonsEnabled: true,
    }
  },
  computed: {
    ...mapGetters(['showFertilizerForm', 'errors', 'company']),
  },
  methods: {
    closeForm() {
      this.$store.commit("SET_SHOW_FERTILIZER_FORM", false);
    },
    submitEditFertilizer() {
      this.buttonsEnabled = false;
      console.log(this.form);
      let data = JSON.parse(JSON.stringify(this.form));
      this.$store.dispatch("storeEditFertilizer", data)
      .then(response => {
        this.buttonsEnabled = true;
        console.log(response.data);
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_FERTILIZER_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        console.log(error);
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    submitNewFertilizer() {
      this.buttonsEnabled = false;
      let data = JSON.parse(JSON.stringify(this.form));
      // A user is only allowed to create a new fertilizer, or to edit one of their own
      // fertilizers.

      console.log(data);
      data.company_id = this.company.id;
      this.$store.dispatch("storeNewFertilizer", data)
      .then(response => {
        console.log(response.data);
        this.buttonsEnabled = true;
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_FERTILIZER_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    removeError(inputFertilizer) {
      this.$store.commit("REMOVE_ERROR", inputFertilizer);
      this.$forceUpdate();
    },
  }
}
</script>
