<template>
  <div>
    <router-link :to="{ name: 'fields' }">Fields</router-link>
  </div>
</template>

<script>
export default {
  name: "Preferences",
  created() {
      this.$store.commit("SET_SHOW_MENU", true);
  },
};
</script>

<style scoped></style>
