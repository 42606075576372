import axios from "axios/index";
import Vue from 'vue';

export default {
  state: {
    allFertilizers: [],
  },
  getters: {
    fertilizers: state => state.allFertilizers.filter(e => e.new_id == null && e.deleted_at == null),
    archivedFertilizers: state => state.allFertilizers.filter(e => e.new_id == null && e.deleted_at != null),
    outDatedFertilizers: state => state.allFertilizers.filter(e => e.new_id != null),
    fertilizersIncludingOutdated: state => state.allFertilizers.filter(e => e.new_id != null || (e.new_id == null && e.deleted_at == null)),
    // fertilizersWithPickedFertilizer(state, getters, rootState) {
    //   return state.allFertilizers.filter(e => (e.new_id == null && e.deleted_at == null) || e.id == rootState['nfrecord'].record.fertilizer_id)
    // }
  },
  mutations: {
    SET_FERTILIZERS(state, allFertilizers) {
      state.allFertilizers = allFertilizers;
    },
    ADD_FERTILIZERS(state, fertilizers) {
      state.allFertilizers = state.allFertilizers.concat(fertilizers);
    },
    UPDATE_FERTILIZER(state, data) {
      // Expects id of old version of field,
      // and a field object of the new version of the field

      // Put field into 'outDatedFertilizers' getter
      Vue.set(state.allFertilizers[state.allFertilizers.findIndex(e => e.id == data.oldId)], 'deleted_at', 1);
      Vue.set(state.allFertilizers[state.allFertilizers.findIndex(e => e.id == data.oldId)], 'new_id', data.new.id);

      // Add the new field
      state.allFertilizers = state.allFertilizers.concat([data.new]);
    },
    ARCHIVE_FERTILIZER(state, id) {
      Vue.set(state.allFertilizers[state.allFertilizers.findIndex(e => e.id == id)], 'deleted_at', 1);
    },
    RESTORE_FERTILIZER(state, id) {
      Vue.set(state.allFertilizers[state.allFertilizers.findIndex(e => e.id == id)], 'deleted_at', null);
    },
    // SET_FERTILIZERS(state, fertilizers) {
    //   state.fertilizers = fertilizers;
    // },
    // ADD_ARCHIVED_FERTILIZERS (state, fertilizers) {
    //   state.archivedFertilizers = state.archivedFertilizers.concat(fertilizers);
    // },
    // DEPRECATE_FERTILIZER(state, data) {
    //   // Set deprecated fertilizer property 'new_id' to the newly added fertilizer.
    //   state.fertilizers[state.fertilizers.findIndex(e => e.id == data.oldId)].new_id = data.newId;
    // },
    // REPLACE_FERTILIZER(state, data) {
    //   state.fertilizers[state.fertilizers.findIndex(e => e.id == data.oldId)] = data.new;
    // },
    // ARCHIVE_FERTILIZER(state, fertilizer) {
    //   const index = state.fertilizers.findIndex(item => item.id === fertilizer.id);
    //   state.fertilizers.splice(index, 1);
    //   // state.fertilizers.splice(state.fertilizers.findIndex(e => e.id == fertilizer.id));
    //   state.archivedFertilizers.concat([fertilizer]);
    // }
  },
  actions: {
    // retrieveFertilizers({ commit }) {
    //   console.log('retrieving');
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/fertilizers").then(response => {
    //         commit("ADD_FERTILIZERS", response.data);
    //         resolve(response);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    storeNewFertilizer({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fertilizers", {
            data
          }).then(response => {
            console.log(response.data);
            commit("ADD_FERTILIZERS", [response.data]);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeEditFertilizer({ commit }, fertilizer) {
      console.log('sending', fertilizer);
      return new Promise((resolve, reject) => {
        axios
          .patch("/fertilizers/" + fertilizer.id, fertilizer)
          .then(response => {
            console.log(response.data);
            let data = {
              oldId: fertilizer.id,
              new: response.data,
            };
            commit("UPDATE_FERTILIZER", data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    archiveFertilizer({ commit }, id) {
      axios
        .delete("/fertilizers/" + id)
        .then(() => {
          commit("ARCHIVE_FERTILIZER", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    restoreFertilizer({ commit }, id) {
      axios
        .patch("/fertilizers/" + id + "/restore")
        .then(() => {
          commit("RESTORE_FERTILIZER", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
  }
}
