<template lang="html">
  <div class="root nutrient__progress__bar" @click="$store.commit('SET_DICTIONARY_CONTENT', 'nutrient_progress_bar')">
    <div class="top">
      <HexNutrient
      :nutrient="nutrient.symbol"
      :colour="nutrient.colour"
      config="small">

      </HexNutrient>
      <div class="progress__bar">
        <div class="planned__bar" :style="{ width: calculatedPercentages.plannedBar }" :class="{ alert: preAndPlannedAmount > targetAmount}" v-if="plannedAmountApplied"></div>
        <div class="pre__bar" :style="{ width: calculatedPercentages.preBar }"></div>
        <div class="target__bar" :style="{ width: calculatedPercentages.targetBar }"></div>
      </div>
    </div>
    <small>{{ preAndPlannedAmount }}/{{ targetAmount }} kg {{ nutrient.symbol }}/ha</small>
  </div>
</template>

<script>
import HexNutrient from "../ui/HexNutrient";

export default {
  components: {
    HexNutrient
  },
  props: {
    nutrient: '',
    preAmount: '',
    plannedAmountApplied: '',
    targetAmount: '',
    config: '',
  },
  computed: {
    preAndPlannedAmount: function () {
      let pre;
      let planned;
      if(!this.preAmount) {
        pre = 0;
      } else {
        pre = this.preAmount;
      }
      if(!this.plannedAmountApplied) {
        planned = 0;
      } else {
        planned = this.plannedAmountApplied;
      }
      return parseFloat(pre + planned).toFixed(0);
    },
    calculatedPercentages: function () {
      var obj = {};

      if(this.targetAmount < this.preAndPlannedAmount) {
        // The target has been reached, so the 'planned bar' is bigger
        let largestNumber = this.preAndPlannedAmount;

        let targetBar = this.targetAmount / largestNumber * 100;
        let preBar = this.preAmount / largestNumber * 100;

        obj['preBar'] = 'calc(' +preBar + '%)';
        obj['targetBar'] = targetBar + '%';
        obj['plannedBar'] = 'calc(100%)';
      } else {
        // The target has not yet been reached
        let largestNumber = this.targetAmount;

        let plannedBar = this.preAndPlannedAmount / largestNumber * 100;
        let preBar = this.preAmount / largestNumber * 100;

        obj['preBar'] = 'calc(' +preBar + '%)';
        obj['targetBar'] = '100%';
        obj['plannedBar'] = 'calc(' + plannedBar + '%)';
      }

      return obj;
    }
  },
  methods: {

  }
}
</script>
