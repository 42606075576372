<template>
  <div></div>
</template>

<script>
export default {
  created() {
    // RESET all local values
    this.$store.commit("RESET");

    // Log out user
    this.$store
      .dispatch("destroyToken")
      .then(() => {
        // redirect user to records page
        this.$router.push({ name: "home" });
      })
      .catch(() => {
        this.$router.push({ name: "home" });
      });
  }
};
</script>
