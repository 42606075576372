// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import Master from "./components/ui/Master";
import router from "./router";
import { store } from "./store/store";
import "./assets/sass/app.scss";
import axios from 'axios/index';

Vue.config.productionTip = false;

// TODO Request interceptor
axios.interceptors.request.use(function(config) {
    // Authorization token if available
    let token = localStorage.getItem('jwt');
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    console.log('ping');

    // Default content type
    config.headers['Content-Type'] = 'application/json';

    return config;
}, function(err) {
    return Promise.reject(err);
});

axios.interceptors.response.use(function(config) {
  console.log('pong');

  return config;
}, function(err) {
  // console.log(err.response.data.errors[0]);
  store.commit("SET_ERRORS", err.response.data.errors);
  // this.$store.commit("SET_ERRORS", err.response.data.errors[0]);
  // TODO Catch all errors here and put them in the store.
  return Promise.reject(err);
});

// Navigation guards:
// These 'rules' make sure that nobody can go to a page they are not allowed to go to
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.token) {
      // Client needs to log in
      next({
        name: "login"
      });
    } else if (to.name === "logout") {
      next();
    } else if (to.name === "login" || to.name === "register"){
      next({ name: "records"});
    } else if (to.name === "setup"){
      next();
    } else {
      store.dispatch("retrieveSetup")
      .then(() => {
        if(store.getters.accountIsSetup) {
          next();
        } else {
          next({
            name: "setup"
          });
        }
      });
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.token) {
      next({
        name: "records"
      });
    } else {
      // Client is not logged in and can visit the page
      next();
    }
  } else {
    next();
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router: router,
  store: store,
  components: { Master },
  template: "<Master/>"
});
