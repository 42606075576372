<template lang="html">
  <div class="hex__container">
    <!-- <div class="hexagon" :class="[{small: config=='small'}, this.colour]"> -->
    <div class="hexagon">
      {{ nutrient }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nutrient: '',
    colour: '',
    config: '',
  }
}
</script>

<style lang="scss" scoped>
</style>
