<template lang="html">
  <section class="step">
    <h3>General</h3>
    <!-- <button type="button" name="button" @click="$store.commit('SET_DICTIONARY_CONTENT', 'test')">What is this?</button> -->
    <ul class="information__table">
      <li>
        <small>Grower name:</small>
        <p><b>{{ user.name }}</b></p>
      </li>
      <li>
        <small>Trading name:</small>
        <p><b>{{ company.name }}</b></p>
      </li>
      <li>
        <small>Record created on:</small>
        <p><b>{{ formatDate(record.created_at) }}</b></p>
      </li>
    </ul>

    <h3>Field</h3>
    <ul class="information__table">
      <li>
        <small>Area:</small>
        <p style="white-space: pre;"><b>{{ parseFloat(pickedField.area) }}</b>&nbsp;ha</p>
      </li>
      <li>
        <small>Soil type:</small>
        <p><b>{{ pickedField.soil_type.name }} </b></p>
      </li>
    </ul>


    <h3>Soil N</h3>
    <table class="table--50" v-if="n_soil_pre">
      <tr>
        <td>test type:</td>
        <td>{{ n_soil_pre.test_type }}</td>
      </tr>
      <tr>
        <td>amount:</td>
        <td>{{ parseFloat(n_soil_pre.amount) }} kg N/ha</td>
      </tr>
      <tr>
        <td>date:</td>
        <td>{{ formatDate(n_soil_pre.date) }}</td>
      </tr>
    </table>
    <p v-else>No soil data</p>


    <h3>Residue N</h3>
    <table class="table--50" v-if="n_residue_pre">
      <tr>
        <td>test type:</td>
        <td>{{ n_residue_pre.test_type }}</td>
      </tr>
      <tr>
        <td>amount:</td>
        <td>{{ parseFloat(n_residue_pre.amount) }} kg N/ha</td>
      </tr>
      <tr>
        <td>date:</td>
        <td>{{ formatDate(n_residue_pre.date) }}</td>
      </tr>
    </table>
    <p v-else>No residue data</p>


    <h3>Fertilizer plan</h3>
    <table class="crop_planning_fertilizer_table">
      <thead>
        <td>Fertilizer</td>
        <td>N</td>
        <td>Rate</td>
        <td>Total</td>
      </thead>
      <tbody>
        <tr v-for="application in fertilizer_application_plans">
          <td>{{ application.fert.name }}</td>
          <td>{{ parseFloat(application.fert.N).toFixed(0) }}%</td>
          <td>{{ parseFloat(application.rate).toFixed(0) }} kg/ha</td>
          <td>{{ parseFloat(application.rate * pickedField.area).toFixed(0) }} kg</td>
        </tr>
      </tbody>
    </table>


    <!-- <PreOverviewToPdf></PreOverviewToPdf> -->
    <div style="visibility: hidden; overflow: hidden; height: 1px;">
      <PreToPdfContent id="pdf-content"></PreToPdfContent>
    </div>

    <nav class="record">
      <ul class="option__group">
        <router-link :to="{ name: 'records', params: {} }"></router-link>
        <li>
          <button type="submit" @click="exportToPdf()">
            Export crop plan to PDF
          </button>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import FormatDate from '../../mixins/formatDate';
// import PreOverviewToPdf from '../export/PreOverviewToPdf';

import {jsPDF} from 'jspdf';
import PreToPdfContent from '../export/PreToPdfContent';

import '../../assets/jsPdfFonts/OpenSans-Regular-normal.js';
import '../../assets/jsPdfFonts/OpenSans-Bold-normal.js';
import '../../assets/jsPdfFonts/Ubuntu-Regular-normal.js';
import '../../assets/jsPdfFonts/Ubuntu-Bold-normal.js';

export default {
  mixins: [FormatDate],
  components: {
    // PreOverviewToPdf,
    PreToPdfContent,
  },
  computed: {
    ...mapGetters([
      'nutrients',
      'fertilizers',
      'fertilizer_application_plans',
      'fertilizer_application_executions',
      'soil_types',
      'test_types',
      'sor',
    ]),
    ...mapGetters('nfrecord', [
      'record',
      'farm',
      'company',
      'user',
      'tracked_nutrients',
      'record_data_pre',
      'n_soil_pre',
      'n_residue_pre',
      'record_data_post',
      'pickedField',
      'pickedCrop',
      'soilPlusResidueAmounts',
      'soilPlusResidueAmountsPost',
      'addedFertilizerPlansAmounts',
      'appliedFertilizerExecutionsAmounts',
      'nitrogenExported',
    ]),
    comparisonTableData() {
      let allNutrientsData = [];
      for(let nutrient in this.tracked_nutrients) {
        let data = [];
        let nutrient_data = [];
        nutrient_data.push(this.tracked_nutrients[nutrient].symbol);
        nutrient_data.push("Pre");
        nutrient_data.push("Post");
        nutrient_data.push("Difference");
        data.push(nutrient_data);

        for(let sr in this.sor) {
          nutrient_data = [];
          nutrient_data.push(Object.keys(this.sor)[+Boolean(!this.sor[sr])]);

          let pre_data = '-';
          if(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])) {
            pre_data = parseFloat(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount);
          }
          nutrient_data.push(pre_data);

          let post_data = '-';
          if(this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])) {
            post_data = parseFloat(this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount);
          }
          nutrient_data.push(post_data);

          let compare_data = '-';
          if(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])
          && this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]))
          {
            compare_data = parseFloat((this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount
            - this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount));
            if(compare_data>0) {
              compare_data = '+'+compare_data;
            }
          }
          nutrient_data.push(compare_data);
          data.push(nutrient_data);
        }
        nutrient_data = [];
        nutrient_data.push("Combined");
        nutrient_data.push(this.soilPlusResidueAmounts[this.tracked_nutrients[nutrient].symbol]);
        nutrient_data.push(this.soilPlusResidueAmountsPost[this.tracked_nutrients[nutrient].symbol]);
        let addedTotalAmounts = this.soilPlusResidueAmountsPost[this.tracked_nutrients[nutrient].symbol] - this.soilPlusResidueAmounts[this.tracked_nutrients[nutrient].symbol];
        if(addedTotalAmounts > 0) {
          addedTotalAmounts = "+"+addedTotalAmounts;
        }
        nutrient_data.push(addedTotalAmounts);
        data.push(nutrient_data);
        allNutrientsData.push(data);
      }
      return allNutrientsData;
    },
  },
  methods: {
    exportToPdf() {
      console.log('generating pdf');
      var doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',

      });
      console.log(doc.getFontList());
      doc.setFont('OpenSans-Regular');
      doc.setFont('OpenSans-Bold');
      doc.setFont('Ubuntu-Bold');
      doc.html(document.getElementById('pdf-content'), {
        callback: function (doc) {
          doc.save();
        },
        x: 0,
        y: 0,
      });
    }
  }
}
</script>
