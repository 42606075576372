<template lang="html">
  <div class="crop_planning_pdf_template">
    <h2>Nutrient Budget - Nitrogen - Post harvest assessment</h2>
    <small>Created on: {{ formatDate(record.created_at) }}</small>
    <div class="general_info">
      <div class="left">
        <table>
          <thead>
            <tr>
              <td colspan="2">Admin</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Grower/Agronomist:</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr>
              <td>Trading name:</td>
              <td>{{ company.name }}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <td colspan="2">Paddock</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Paddock name:</td>
              <td>{{ pickedField.name }}</td>
            </tr>
            <tr>
              <td>Area:</td>
              <td>{{ parseFloat(pickedField.area) }} ha</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="right">
        <table>
          <thead>
            <tr>
              <td colspan="2">Crop</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Crop name:</td>
              <td>{{ pickedCrop.name }}</td>
            </tr>
            <tr>
              <td>Yield expected:</td>
              <td>{{ parseFloat(record.target_yield) }} t/ha</td>
            </tr>
            <tr>
              <td>Planting date:</td>
              <td v-if="record.planting_date != null">{{ formatDate(record.planting_date) }}</td>
              <td v-else>-</td>
            </tr>
            <tr>
              <td>{{ picked_field_data['N'][0].test_type }}:</td>
              <td>{{ parseFloat(picked_field_data['N'][0].amount) }} kg N/ha</td>
            </tr>
            <tr>
              <td>Recommended:</td>
              <td>{{ targetYieldRecommendedNitrogenPerHectare }}  kg N/ha</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="inputs">
      <h3>Inputs</h3>
      <table class="no_margin_top">
        <tr>
          <td>Residue N</td>
          <td>{{ parseFloat(picked_field_data['N'][1].amount) }} kg N/ha</td>
        </tr>
      </table>
      <table class="less_margin_top">
        <thead>
          <tr>
            <td>Fertilizer name</td>
            <td>Total</td>
            <td>%N</td>
            <td>Rate</td>
            <td>N in application</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="application, key in fertilizer_application_plans" :key="key">
            <td>{{ application.fert.name }}</td>
            <td>{{ parseFloat(application.rate * pickedField.area).toFixed(0) }} kg</td>
            <td>{{ parseFloat(application.fert.N) }}</td>
            <td>{{ parseFloat(application.rate) }} kg/ha</td>
            <td>{{ parseFloat(application.rate * application.fert.N / 100).toFixed() }} kg N/ha</td>
          </tr>
          <tr class="total">
            <td colspan="4">Total input:</td>
            <td>{{ parseFloat(this.totalInput).toFixed() }} kg N/ha</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr>
    <div class="planned_balance_calculation">
      <table>
        <tr>
          <td colspan="2">Total input</td>
          <td>{{ parseFloat(this.totalInput).toFixed() }} kg N/ha</td>
        </tr>
        <tr>
          <td>Recommended</td>
          <td class="calculation">-</td>
          <td>{{ parseFloat(targetYieldRecommendedNitrogenPerHectare).toFixed() }} kg N/ha</td>
        </tr>
        <tr class="total">
          <td colspan="2">Planned Nitrogen balance</td>
          <td colspan="2">{{ parseFloat(this.totalInput - targetYieldRecommendedNitrogenPerHectare).toFixed() }} kg N/ha</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import FormatDate from "../../mixins/formatDate";

export default {
  mixins: [FormatDate],
  computed: {
    ...mapGetters('nfrecord', [
      'record',
      'user',
      'pickedField',
      'pickedCrop',
      'targetYieldRecommendedNitrogenPerHectare',
      'appliedFertilizerExecutionsAmounts',
    ]),
    ...mapGetters([
      'company',
      'picked_field_data',
      'fertilizer_application_plans',
    ]),
    totalInput() {
      return (parseFloat(this.appliedFertilizerExecutionsAmounts['N']) +
      parseFloat(this.picked_field_data['N'][1].amount)).toFixed(2);
    }
  }
}
</script>
