<template>
  <div>
    <p>Landing page content here.</p>
  </div>
  <!--

  The landing page

  This page must be very simple. Only a simple sign in button on the top-right, and the logo on the top-left.
  Below this there will be an eye catching thing that will have a register button.
  Below that there should be some selling points.
  Some visuals of the application.
  Contact details / write an email.

  must-have:
  -catchy headline with optional subline. contrast must let the headline stand out
  -What can the website do for the user? - short and clear
  -supporting images
  -contact information
  -cta's
  -visually appealing
  -less is more - not be overwhelming
  -include sales and discounts
  -sell the product by showing all the unique aspects, compared to similar products
  -the fewer questions and dilemmas the client has, the more likely it will be a success
  -customer reviews and testimonials
  -

  -->
</template>

<script>
export default {
  name: "Landing",
  created() {
  },
};
</script>

<style scoped>
a {
  padding: 10px;
  margin: 20px;
  position: absolute;
  top: 40px;
  border: 1px grey solid;
}
p {
  margin-top: 200px;
}</style>
