<template>
  <div id="app" :class="{ noScroll: showShadow }">

    <transition name="top-drawer">
      <Dictionary v-if="dictionaryContent"></Dictionary>
    </transition>

    <transition name="fade">
      <div class="modal__shadow" v-if="dictionaryContent" @click="[closeAllModals, $store.commit('SET_DICTIONARY_CONTENT', null)]"></div>
    </transition>

    <Nav v-if="this.$route.name != 'record' && this.$route.name != 'not_found'"></Nav>

    <router-view class="router-view"></router-view>
  </div>
</template>

<script>
import Nav from "./Nav";
import Dictionary from "./Dictionary";
import { mapGetters } from "vuex";

export default {
  name: "Master",
  components: {
    Nav,
    Dictionary
  },
  computed: {
    ...mapGetters(["renderMenu", "showShadow", 'dictionaryContent', 'returnHatch']),
  },
  created() {
    // this.$store.dispatch("retrieveAccountInformation");
  },
  methods: {
    goToReturnHatch() {
      let returnHatch = this.returnHatch;
      this.$store.commit('SET_RETURN_HATCH', '');
      this.$router.go(-1);
    },
    menuToggle() {
      this.$store.commit("TOGGLE_MENU");
    },

    closeAllModals() {
      this.$store.commit("CLOSE_ALL_MODALS");
    }
  }
};
</script>

<style scoped lang="scss">
.router-view {
}
//
// .shader {
//   pointer-events: none;
//   position: fixed;
//   width: 100vw;
//   top: 0; // temporary fix for setup
//   height: 100vh;
//   background-color: black;
//   opacity: .3;
//   z-index: 9;
//   transition: opacity 0.2s ease-in-out;
//   pointer-events: all;
// }
//
// #app.noScroll {
//   overflow-y: hidden;
//   height: 100vh;
// }
//
// .loading {
//   z-index: 20;
//   bottom: 0;
//   background-color: coral;
//   width: 100vw;
//   position: fixed;
// }
</style>
