import Vue from "vue";
import Router from "vue-router";
import Landing from "../components/marketing/Landing";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import Register from "../components/auth/Register";
import Setup from "../components/auth/Setup";
import About from "../components/marketing/About";
import RecordList from "../components/records/RecordList";
import Record from "../components/records/Record.vue";
import FieldPreferences from "../components/preferences/FieldPreferences";
import Preferences from "../components/preferences/Preferences";
import FarmList from "../components/preferences/FarmList";
import Account from "../components/account/Account";
import NotFound from "../components/ui/NotFound";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Landing
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: "/setup",
      name: "setup",
      component: Setup,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/records",
      name: "records",
      component: RecordList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/records/:id",
      name: "record",
      component: Record,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/preferences",
      name: "preferences",
      component: Preferences,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/preferences/farms",
      name: "farms",
      component: FarmList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/preferences/fields",
      name: "fields",
      component: FieldPreferences,
      meta: {
        //requiresAuth: true
      }
    },
    {
      path: "/account",
      name: "account",
      component: Account,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/404',
      name: "not_found",
      component: NotFound
    },
    {
      path: '*',
      redirect: '/404'
    }
  ],
  mode: "history"
});
