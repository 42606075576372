<template>
  <div>
    Account page
  </div>
</template>

<script>
export default {
  name: "Account",
  created() {
      this.$store.commit("SET_SHOW_MENU", true);
  },
};
</script>

<style scoped></style>
