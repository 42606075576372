<template lang="html">
  <div class="drawer">
    <section>

      <h3 v-if="setting=='new'">New Field</h3>
      <h3 v-if="setting=='edit'">Edit Field</h3>

      <ul class="dynamic__form">
        <li>
          <label for="name">Name:</label>
          <input type="text" v-model="form.name" id="name"
          :class="{ error: errors.name }"
          @blur="[$store.commit('REMOVE_ERROR', 'name'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.name">{{ errors.name[0] }}</div>
        </li>

        <li>
          <label for="area">Area:</label>
          <input type="number" v-model="form.area" id="area"
          :class="{ error: errors.area }"
          @blur="[$store.commit('REMOVE_ERROR', 'area'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.area">{{ errors.area[0] }}</div>
          <span class="unit">ha</span>
        </li>

        <li>
          <label for="soil_type">Soil type:</label>
          <select type="text" v-model="form.soil_type_id" id="soil_type"
          :class="{ error: errors.soil_type_id }"
          @blur="[$store.commit('REMOVE_ERROR', 'soil_type_id'), $forceUpdate()]">
            <option v-for="soil_type, key in soil_types" :key="key" :value="soil_type.id">{{ soil_type.name }}</option>
          </select>
          <div class="validation__error" v-if="errors.soil_type_id">{{ errors.soil_type_id[0] }}</div>
        </li>
      </ul>

      <ul class="option__group" v-if="setting == 'edit'">
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitEditField">Edit</button>
      </ul>

      <ul class="option__group" v-else>
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitNewField">Create New</button>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  created(){
    this.$store.commit("RESET_ERRORS");
    if(this.data != undefined) {
      this.setting = 'edit';
      this.form = JSON.parse(JSON.stringify(this.data));
      this.form.area = parseFloat(this.form.area);
    }
  },
  props: {
    data: '',
  },
  data() {
    return {
      form: {},
      setting: 'new',
      buttonsEnabled: true,
    }
  },
  computed: {
    ...mapGetters(['showFieldForm', 'errors', 'farm', 'soil_types']),

  },
  methods: {
    closeModal() {
      this.$store.commit("SET_SHOW_FIELD_FORM", false);
    },
    submitEditField() {
      this.buttonsEnabled = false;
      console.log(this.form);
      let data = JSON.parse(JSON.stringify(this.form));
      this.$store.dispatch("storeEditField", data)
      .then(response => {
        this.buttonsEnabled = true;
        this.$emit("newId", response.data[0].id);
        this.$store.commit("SET_SHOW_FIELD_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        console.log(error);
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    submitNewField() {
      this.buttonsEnabled = false;
      let data = {
        name: this.form.name,
        soil_type_id: this.form.soil_type_id,
        area: this.form.area,
        farm_id: this.farm.id,
      };
      this.$store.dispatch("storeNewField", data)
      .then(response => {
        this.buttonsEnabled = true;
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_FIELD_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    removeError(inputField) {
      this.$store.commit("REMOVE_ERROR", inputField);
      this.$forceUpdate();
    },
  }
}
</script>
