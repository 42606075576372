<template lang="html">
  <div class="drawer">
    <section>
      <h3>DISCLAIMER</h3>
      <p>This calculator and any associated information are general in nature and not intended as a substitute
for specific professional advice on any matter and should not be relied upon for that purpose. No
endorsement of named products is intended nor is any criticism of other alternative but unnamed
products. It has been prepared and made available to all persons and entities strictly on the basis
that LandWISE Inc., its researchers, authors and funders and the publishers and authors of “Nutrient
Management for Vegetable Crops in New Zealand” are fully excluded from any liability for damages
arising out of any reliance in part or in full upon any of the information for any purpose.</p>

      <h3>ACKNOWLEDGEMENT</h3>
      <p>This calculator uses information drawn for “Nutrient Management for Vegetable Crops in New
Zealand” by JB Reid and JD Morton, published in 2019 by Horticulture New Zealand on behalf of the
Vegetable Research and Innovation Board and the Fertiliser Association of New Zealand. Book
preparation was jointly funded by Plant &amp; Food Research (Sustainable Agricultural Ecosystems
Programme), the Fertiliser Association of New Zealand, and the Vegetable Research and Innovation
Board of Horticulture New Zealand Incorporated.
Many vegetable crops are not covered in “Nutrient Management for Vegetable Crops in New
Zealand”. Where information becomes available it may be added to the calculator and the source of
information will be stated. Users have the option to add crops of their choice to the calculator using
their own information.</p>

      <ul class="option__group">
        <li><button type="button" @click="$store.commit('SET_SHOW_DISCLAIMER_AND_ACKNOWLEDGEMENTS', false)">Close</button></li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
