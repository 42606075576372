import axios from "axios/index";

export default {
  namespaced: true,
  state: {
    reactiveDependency: false,
    record: {},
    field_data: [],
    record_data_pre: [],
    record_data_post: [],
    farm: {},
    fert_app_plans: [],
    fert_app_executions: [],
    tracked_nutrients: [],
    company: {},
    user: {},
  },
  getters: {
    record: state => state.record,
    farm: state => state.farm,
    company: state => state.company,
    user: state => state.user,
    tracked_nutrients: state => state.tracked_nutrients,
    record_data_pre: state => state.record_data_pre,
    record_data_post: state => state.record_data_post,
    n_soil_pre(state) {
      if(!state.record_data_pre) {
        return null;
      }
      return state.record_data_pre.find(e => e.soil_or_residue == 0 && e.pivot.pre_or_post == 0);
    },
    n_residue_pre(state) {
      if(!state.record_data_pre) {
        return null;
      }
      let amount = state.record_data_pre.find(e => e.soil_or_residue == 1 && e.pivot.pre_or_post == 0);
      if(amount == undefined) {
        return 0;
      }
      return amount;
    },
    pickedField(state, getters, rootState, rootGetters) {
      return rootGetters.fieldsWithPickedField.find(e => e.id == state.record.field_id);
    },
    pickedCrop(state, getters, rootState, rootGetters) {
      return rootGetters.cropsWithPickedCrop.find(e => e.id == state.record.crop_id);
    },

    standardMarketableYieldNitrogenRecommendedPerHectare(state, getters) {
      if(!getters.n_soil_pre || !getters.pickedCrop) {
        return -1;
      }

      if(getters.pickedCrop.n_soil_saturated_at){
        if(getters.n_soil_pre.amount <= getters.pickedCrop.n_soil_saturated_at) {
          return parseFloat(getters.pickedCrop.set_n_recommendation);
        }
      }
      // console.log('pre n amount: ' + getters.n_soil_pre.amount);
      // console.log('crop.soil_factor: ' + getters.pickedCrop.soil_factor);
      // console.log('pre n amount * soil_factor: ' + (getters.pickedCrop.soil_factor * getters.n_soil_pre.amount));
      // console.log('crop.offset: ' + getters.pickedCrop.offset);
      // console.log('standard yield n required: ' + (parseFloat(getters.n_soil_pre.amount * getters.pickedCrop.soil_factor) +
      // parseFloat(getters.pickedCrop.offset)));
      return parseFloat(
        (getters.n_soil_pre.amount * getters.pickedCrop.soil_factor) +
        parseFloat(getters.pickedCrop.offset)
      ).toFixed(2);
    },
    targetYieldRecommendedNitrogenPerHectare(state, getters) {
      /*
      Calculate what the crop needs in a situation where
      standard marketable yield is prefered

      Calculates based on:
      - soil N amount
      - crop soil factor N
      - crop offset
      - standard target yield
      - actual target yield

      */
      if(!getters.n_soil_pre || !getters.pickedCrop) {
        return '-';
      }
      // console.log('target yield n required: ' + parseFloat(
      //   getters.standardMarketableYieldNitrogenRecommendedPerHectare *
      //   state.record.target_yield /
      //   getters.pickedCrop.standard_marketable_yield
      // ).toFixed(0));
      return parseFloat(
        getters.standardMarketableYieldNitrogenRecommendedPerHectare *
        state.record.target_yield /
        getters.pickedCrop.standard_marketable_yield
      ).toFixed(0);
    },
    soilPlusResidueAmounts(state, getters, rootState, rootGetters) {
      if(state.reactiveDependency)
      if(Object.keys(rootGetters.picked_field_data).length == 0) {
        return false;
      }

      var obj = {};

      for(var data in rootGetters.picked_field_data) {
        var soil = parseFloat(rootGetters.picked_field_data[data][0]['amount']);
        var residue = parseFloat(rootGetters.picked_field_data[data][1]['amount']);
        if(Number.isNaN(residue)) {
          obj[data] = soil;
        } else {
          obj[data] = soil + residue;
        }
      }

      return obj;
    },
    soilPlusResidueAmountsPost(state, getters, rootState, rootGetters) {
      if(state.reactiveDependency)
      if(Object.keys(rootGetters.picked_field_data_post).length == 0) {
        return false;
      }

      var obj = {};

      for(var data in rootGetters.picked_field_data_post) {
        var soil = parseFloat(rootGetters.picked_field_data_post[data][0]['amount']);
        var residue = parseFloat(rootGetters.picked_field_data_post[data][1]['amount']);
        if(Number.isNaN(residue)) {
          obj[data] = soil;
        } else {
          obj[data] = soil + residue;
        }
      }

      return obj;
    },
    addedFertilizerPlansAmounts(state, getters, rootState, rootGetters) {
      if(Object.keys(rootGetters.picked_field_data).length == 0) {
        return false;
      }
      var obj = {};
      for(var data in rootGetters.picked_field_data) {
        var fert = 0;
        rootGetters.fertilizer_application_plans.forEach(fa => {
          fert = fert + (fa.fert[data] * fa.rate / 100);
        });
        obj[data] = Math.round(parseFloat(fert));
      }

      return obj;
    },
    appliedFertilizerExecutionsAmounts (state, getters, rootState, rootGetters) {

      let obj = {};

      state.tracked_nutrients.forEach(t => {
        let added = 0;
        rootGetters.fertilizer_application_executions.forEach(e => {
          added += e.fert[t.symbol] * e.rate / 100;
        });
        obj[t.symbol] = parseFloat(added);
      });

      return obj;
    },
    nitrogenExported (state) {
      return state.record.yield * state.record.n_yield;
    },
    stepHarvestComplete () {
      return false
    },
  },
  mutations: {
    SET_RECORD(state, record) {
      state.record = record;
    },
    SET_FARM(state, farm) {
      state.farm = farm;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TRACKED_NUTRIENTS(state, data) {
      state.tracked_nutrients = data;
    },
    SET_RECORD_DATA_PRE(state, data) {
      state.record_data_pre = data;
    },
    SET_RECORD_DATA_POST(state, data) {
      state.record_data_post = data;
    },
    TOGGLE_REACTIVE_DEPENDENCY(state) {
      state.reactiveDependency = !state.reactiveDependency;
    },
    SET_RECORD_PICKED_FIELD(state, id) {
      state.record.field_id = id;
    },
  },
  actions: {
    retrieveRecord({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/records/" + id)
          .then(response => {
            console.log(response.data);
            commit("SET_USER", response.data[0].farm.company.user);

            let company = response.data[0].farm.company;
            delete company.user;
            commit("SET_COMPANY", company);

            let farm = response.data[0].farm;
            delete farm.company;
            commit("SET_FARM", farm);

            commit("SET_TRACKED_NUTRIENTS", response.data[0].tracked_nutrients);

            let recordDataPre = response.data[0].record_data.filter(e => e.pivot.pre_or_post == 0);
            commit("SET_RECORD_DATA_PRE", recordDataPre);

            let recordDataPost = response.data[0].record_data.filter(e => e.pivot.pre_or_post == 1);
            commit("SET_RECORD_DATA_POST", recordDataPost);

            if(response.data[1].field) {
              commit("ADD_FIELDS", response.data[1].field, {root: true});
            }

            if(response.data[1].crop) {
              commit("ADD_CROPS", response.data[1].crop, {root: true});
            }

            // TODO ^
            if(response.data[1].fertilizers) {
              commit("ADD_FERTILIZERS", response.data[1].fertilizers, {root: true});
            }

            commit("SET_FERTILIZER_APPLICATION_PLANS", response.data[0].fert_app_plans, {root: true});
            commit("SET_FERTILIZER_APPLICATION_EXECUTIONS", response.data[0].fert_app_executions, {root: true});
            var record = response.data[0];
            console.log(record.record_data);
            delete record.farm;
            delete record.tracked_nutrients;
            delete record.fert_app_plans;
            delete record.fert_app_executions;
            delete record.crop;
            delete record.field;
            delete record.record_data;
            commit("SET_RECORD", record);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateRecord({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch("/records/" + data.id, data.recordData)
          .then(response => {
            commit("SET_RECORD", response.data);
            // console.log(response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    pickField({ commit }, data) {

      return new Promise((resolve, reject) => {
        axios
          .post("/records/" + data.recordId + "/select-field", {
            fieldId: data.fieldId
          })
          .then(response => {
            commit("SET_RECORD_PICKED_FIELD", response.data.id);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    trackNutrients({ commit, rootState }, data) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .post("/records/" + data.recordId + "/track-nutrients", {
            nutrients: data.nutrients
          })
          .then(response => {
            commit("SET_TRACKED_NUTRIENTS", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    saveRecordFieldData ({ commit }, data) {
      console.log(data);
      return new Promise((resolve, reject) => {
        axios
          .post("records/" + data.recordId + "/record-data", {
            data: data.recordData,
            pre_or_post: data.pre_or_post
          })
          .then(response => {
            let recordDataPre = response.data.filter(e => e.pivot.pre_or_post == 0);
            commit("SET_RECORD_DATA_PRE", recordDataPre);

            let recordDataPost = response.data.filter(e => e.pivot.pre_or_post == 1);
            commit("SET_RECORD_DATA_POST", recordDataPost);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    pickCrop ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/records/" + data.recordId + "/select-crop", {
            cropId: data.cropId
          })
          .then(response => {
            commit("SET_PICKED_CROP", response.data.crop_id);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    pickPlantingDate({ commit }, data) {
      console.log(data);
      return new Promise((resolve, reject) => {
        axios
          .patch("/records/" + data.recordId, {
            planting_date: data.plantingDate
          })
          .then(response => {
            console.log(response.data);
            commit("SET_PLANTING_DATE", response.data.planting_date);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    pickPlannedHarvestDate({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/records/" + data.recordId + "/select-planned-harvest-date", {
            plannedHarvestDate: data.plannedHarvestDate
          })
          .then(response => {
            commit("SET_PLANNED_HARVEST_DATE", response.data.planned_harvest_date);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    confirmPlanning ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/records/" + data.recordId + "/confirm-planning")
          .then(response => {
            commit("SET_CONFIRM_PLANNING", true);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeNewFertilizerApplicationExecution({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
        .post("/fertilizer-application-execution", data)
        .then(response => {
          commit("SET_RECORD_FERTILIZER_APPLICATION_EXECUTIONS", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
  }
}
