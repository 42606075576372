import axios from "axios/index";
import router from "../../router";

export default {
  state: {
    token: localStorage.getItem("access_token") || null
    // TODO store token elsewhere, as this is unsafe to XSS
  },
  getters: {
    token: state => state.token,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    DESTROY_TOKEN(state) {
      state.token = null;
    }
  },
  actions: {
    retrieveToken({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", {
            email: credentials.email,
            password: credentials.password
          })
          .then(response => {
            const token = response.data.access_token;

            // token has to be stored in order to prevent user from having to sign in again after refresh
            // Store token in cookie, vulnarable to csrf attacks
            // or in local vulnarable to cross site scripting by malicious users

            // Local storage way:
            localStorage.setItem("access_token", token);
            commit("SET_TOKEN", token);

            // Call resolve as awnser to promise
            resolve(response);
          })
          .catch(error => {
            // Call reject as awnser to promise
            reject(error);
          });
      });
    },
    // Logout
    destroyToken({ commit, state }) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;

      if (state.token) {
        return new Promise((resolve, reject) => {
          axios
            .post("/logout")
            .then(response => {
              // Update local Storage
              localStorage.removeItem("access_token");

              // Update local store
              commit("DESTROY_TOKEN");

              // Call resolve as awnser to promise
              resolve(response);
            })
            .catch(error => {
              // Update local Storage
              localStorage.removeItem("access_token");

              // Update local store
              commit("DESTROY_TOKEN");

              // Call reject as awnser to promise
              reject(error);
            });
        });
      }
    },
    register({ dispatch, commit }, credentials) {

      return new Promise((resolve, reject) => {
        axios
          .post("/register", {
            name: credentials.name,
            email: credentials.email,
            password: credentials.password
          })
          .then(response => {
            // Log user in
            dispatch("retrieveToken", {
              email: credentials.email,
              password: credentials.password
            })
              .then(() => {
                router.push({ name: "setup" });
              })
              .catch(error => {
                console.log("registered account but not logged in");
                console.log(error);
              });
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOADING", "");
            // Call reject as awnser to promise
            reject(error);
          });
      });
    }
  }
};
