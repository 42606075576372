<template lang="html">

</template>

<script>
export default {

  created() {
      this.$store.commit("SET_SHOW_MENU", true);
  },
}
</script>

<style lang="css" scoped>
</style>
