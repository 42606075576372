<template lang="html">
  <div class="main__navigation">
    <nav v-if="token">
      <router-link :to="{ name: 'records', params: {} }">Home</router-link>
      <router-link :to="{ name: 'logout', params: {} }">Logout</router-link>
    </nav>
    <nav v-else>
      <router-link :to="{ name: 'home', params: {} }">Home</router-link>
      <router-link :to="{ name: 'login', params: {} }">Login</router-link>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters(['token']),
  }
}
</script>
