<template>
  <section class="login-register">
    <div class="login-register__header">
      <!--<img src="@/assets/logo.png">-->
      <h1>NutBud</h1>
      <div class="links">
        <h3 class="active">Log in</h3>
        <h3><router-link :to="{ name: 'register', params: {} }">Register</router-link></h3>
      </div>
      <p>Sign in to continue</p>
    </div>
    <form action="#" @submit.prevent="login" class="login__form">
      <ul class="dynamic__form">
        <li>
          <p class="input__error w--80">
            <span  v-if="error">
              {{ error }}
            </span>
          </p>
            <label for="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            ref="email"
            v-model="email"
            required
          />
        </li>
        <li>
          <label for="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            v-model="password"
            required
          />
        </li>
      </ul>
      <ul class="option__group">
        <li>
          <SubmitButton :class="submitButtonStatus" :status='this.submitButtonStatus' :text="'Continue'"></SubmitButton>
        </li>
      </ul>
    </form>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SubmitButton from "../ui/SubmitButton";

export default {
  name: "Login",
  components: {
    SubmitButton
  },
  data() {
    return {
      error: "",
      email: "",
      password: "",
      submitButtonStatus: 'idle'
    };
  },
  created() {
  },
  mounted() {
    this.$refs.email.focus();
  },
  computed: {
    ...mapGetters(["company"])
  },
  methods: {
    login() {
      // Clean up error messages
      this.error = "";
      this.submitButtonStatus = 'loading';

      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          // Check if user has to complete account information
          this.submitButtonStatus = 'success';
          this.$store
            .dispatch("retrieveSetup")
            .then(response => {
              if (response.company === null) {
                // Company is not setup yet.
                this.$router.push({ name: "setup" });
              } else {
                this.$store.commit("SET_COMPANY", response.company);
                if (response.farm === null) {
                  this.$router.push({ name: "setup" });
                } else {
                  // Default header
                  this.$router.push({ name: "records" });
                }
              }
            })
            .catch(error => {
              this.submitButtonStatus = 'failure';
              console.log(error);
            });
        })
        .catch(error => {
          this.submitButtonStatus = 'failure';
          this.error = "Username or password is invalid";
        });

      //TODO: catch the errors when filling in incorrect credentials
    }
  }
};
</script>

<style type="text/css" lang="scss" scoped>
// form {
//   margin-top: 40px;
// }
// button {
//   border: none;
//   position: relative;
//   background: #232526;  /* fallback for old browsers */
//   background: -webkit-linear-gradient(to left, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
//   background: linear-gradient(to left, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
//   color: white;
//   font-weight: bold;
//
//   &:disabled {
//     background: rgba(#232526, 0.8);
//   }
//
//   &:active{
//     // TODO Fix blue color over button when pressed
//     background: rgb(0, 0, 0);
//     top: 3px;
//     -webkit-box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.75);
//     -moz-box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.75);
//     box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.75);
//     text-decoration: none;
//   }
//
//   &.secondary {
//     background: #FAFAFA;
//     color: black;
//     border: black solid 2px;
//   }
//
//   &.aSyncButton {
//   }
// }
</style>
