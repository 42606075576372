export default {
  state: {
    showShadow: false,
    dictionaryContent: null,
    loading: '',
    disclaimerAndAcknowledgements: false,

    // renderMenu: false,
    // menuIsOpen: false,

    showFieldPreferences: false,
    showCropPreferences: false,
    showFertilizerPreferences: false,

    showNewFieldModal: false,
    showFieldDataModal: false,
    showNewFertilizerApplicationExecutionModal: false,

    showFieldForm: null,
    showCropForm: null,
    showFertilizerForm: null,
    showNewFertilizerApplicationPlanForm: false,

    selectFieldDataModal: null,
    showNewFertilizerApplicationPlanModal: false,
    showFertilizerFormModal: false,
    sor: {
      'Soil': 0,
      'Residue': 1,
    },
    returnHatch: '',
  },

  getters: {
    showShadow: state => state.showShadow,
    dictionaryContent: state => state.dictionaryContent,
    loading: state => state.loading,
    disclaimerAndAcknowledgements: state => state.disclaimerAndAcknowledgements,

    // renderMenu: state => state.renderMenu,
    // menuIsOpen: state => state.menuIsOpen,

    showFieldPreferences: state => state.showFieldPreferences,
    showCropPreferences: state => state.showCropPreferences,
    showFertilizerPreferences: state => state.showFertilizerPreferences,

    showNewFieldModal: state => state.showNewFieldModal,
    showFieldDataModal: state => state.showFieldDataModal,
    showNewFertilizerApplicationExecutionModal: state => state.showNewFertilizerApplicationExecutionModal,

    showFieldForm: state => state.showFieldForm,
    showCropForm: state => state.showCropForm,
    showFertilizerForm: state => state.showFertilizerForm,
    showNewFertilizerApplicationPlanForm: state => state.showNewFertilizerApplicationPlanForm,
    selectFieldDataModal: state => state.selectFieldDataModal,
    showNewFertilizerApplicationPlanModal: state => state.showNewFertilizerApplicationPlanModal,
    showFertilizerFormModal: state => state.showFertilizerFormModal,
    sor: state => state.sor,
    returnHatch: state => state.returnHatch,
  },

  mutations: {
    RESET(state) {
      state.showShadow = false;
      state.loading = false;
      // state.renderMenu = false;
      // state.menuIsOpen = false;

      state.showFieldPreferences = false;
      state.showCropPreferences = false;
      state.showFertilizerPreferences = false;

      state.showNewFieldModal = false;
      state.showFieldDataModal = false;
      state.showNewFertilizerApplicationExecutionModal = false;

      state.showFieldForm = null,
      state.showCropForm = null,
      state.showFertilizerForm = null,
      state.showNewFertilizerApplicationPlanForm = false;
      state.selectFieldDataModal = null;
      state.showNewFertilizerApplicationPlanModal = false;
      state.showFertilizerFormModal = false;
      state.returnHatch = '';
    },
    SET_SHOW_DISCLAIMER_AND_ACKNOWLEDGEMENTS(state, status) {
      state.disclaimerAndAcknowledgements = status;
    },
    SET_SHADER(state, status) {
      state.showShadow = status;
    },
    SET_DICTIONARY_CONTENT(state, content) {
      state.dictionaryContent = content;
    },
    SET_SHOW_NEW_FERTILIZER_APPLICATION_PLAN_FORM(state, status) {
      state.showNewFertilizerApplicationPlanForm = status;
    },
    SET_SHOW_FIELD_PREFERENCES (state, status) {
      state.showFieldPreferences = status;
    },
    SET_SHOW_CROP_PREFERENCES (state, status) {
      state.showCropPreferences = status;
    },
    SET_SHOW_FERTILIZER_PREFERENCES (state, status) {
      state.showFertilizerPreferences = status;
    },
    SET_SHOW_FIELD_FORM(state, status) {
      state.showFieldForm = status;
    },
    SET_SHOW_CROP_FORM(state, status) {
      state.showCropForm = status;
    },
    SET_SHOW_FERTILIZER_FORM(state, status) {
      state.showFertilizerForm = status;
    },
    SET_SHOW_NEW_FERTILIZER_APPLICATION_EXECUTION_MODAL(state, status) {
      state.showNewFertilizerApplicationExecutionModal = status;
    },
    SET_SHOW_NEW_FIELD_MODAL(state, status) {
      state.showNewFieldModal = status;
    },
    SET_SHOW_FIELD_DATA_MODAL(state, status) {
      state.showFieldDataModal = status;
    },
    SET_SHOW_NEW_FERTILIZER_APPLICATION_PLAN_MODAL(state, status) {
      state.showNewFertilizerApplicationPlanModal = status;
    },
    SET_SHOW_SELECT_FIELD_DATA_MODAL(state, status) {
      state.selectFieldDataModal = status;
    },
    SET_SHOW_NEW_FERTILIZER_MODAL (state, status) {
      state.showFertilizerFormModal = status;
    },
    CLOSE_ALL_MODALS(state) {
      state.showShadow = false;
      state.showNewFieldModal = false;
      state.showFieldDataModal = false;
      state.showNewFertilizerApplicationExecutionModal = false;
      state.selectFieldDataModal = null;
      state.showNewFertilizerApplicationPlanModal = false;
      state.showFertilizerFormModal = false;
    },
    SET_RETURN_HATCH(state, status) {
      state.returnHatch = status;
    }
  }
};
