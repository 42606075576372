import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

import ui from "./modules/ui";
import errors from "./modules/errors";
import auth from "./modules/auth";
import preferences from "./modules/preferences";
import fields from "./modules/fields";
import field_data from "./modules/field_data";
import nfrecord from "./modules/record";
import nfrecords from "./modules/records";
import crops from "./modules/crops";
import ferts from "./modules/ferts";
import fertilizer_applications from "./modules/fertilizer_applications";
import nutrients from "./modules/nutrients";
import test_types from "./modules/test_types";

Vue.use(Vuex);
// axios.defaults.baseURL = "http://192.168.1.159:8000/api"; // TODO change to api port
axios.defaults.baseURL = "http://nutbuddy.landwise.org.nz/api"; // TODO change to api port

export const store = new Vuex.Store({
  modules: {
    ui,
    errors,
    auth,
    preferences,
    fields,
    field_data,
    nfrecord,
    nfrecords,
    crops,
    ferts,
    fertilizer_applications,
    nutrients,
    test_types,
  },
  router: {
    router
  },
});
