import axios from "axios/index";

export default {
  state: {
    test_types: {}
  },
  getters: {
    test_types: state => state.test_types
  },
  mutations: {
    SET_TEST_TYPES(state, test_types) {
      state.test_types = test_types;
    },
    RETRIEVE_TEST_TYPES(state, test_types) {
      state.test_types = test_types;
    }
  },
  actions: {
    retrieve_test_types({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/test_types")
          .then(response => {
            commit("RETRIEVE_TEST_TYPES", response.data);
            resolve(response);
          })
          .catch(error => {
            console.log('failed');
            reject(error);
          });
      });
    }
  }
}
