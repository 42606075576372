<template lang="html">
  <aside>

    <div class="step__top__bar container">
      <router-link class="exit" :to="{ name: 'records', params: {} }">
        <img src="@/assets/img/back.svg">
        Exit
      </router-link>
      <div class='right'>
        <p class="small">
          <img class="icon" src="@/assets/img/record-icon.svg" alt="">
          <small>Record: {{ record.id }}</small>
        </p>
        <nav>
          <div class="previous" @click="previous()" :class="{ disabled: previousDisabled }">
            <img src="@/assets/img/nav-arrow.svg" alt="">
          </div>
          <h1>{{ title }}</h1>
          <div class="next" @click="next()" :class="{ disabled: nextDisabled }">
            <img src="@/assets/img/nav-arrow.svg" alt="">
          </div>
        </nav>
      </div>
    </div>

    <transition name="fade">
      <div class="steps__nav" ref="nav" v-if="stepsNavOpen">
        <div class="nav__top__background">
          <router-link :to="{ name: 'records' }">
            <div>
              <img src="@/assets/img/next.svg" alt="">
              Records Overview
            </div>
          </router-link>
        </div>
        <div class="nav">
          <h2><b>Crop planning</b></h2>
          <a href="#" class="step__link" ref="1" :class="{ here: this.$parent.step == 1 }" @click="goTo(1)">Configure</a>
          <a href="#" class="step__link" ref="2" :class="{ here: this.$parent.step == 2 }" @click="goTo(2)">Field</a>
          <a href="#" class="step__link" ref="3" :class="{ here: this.$parent.step == 3 }" @click="goTo(3)">Crop</a>
          <a href="#" class="step__link" ref="4" :class="{ here: this.$parent.step == 4 }" @click="goTo(4)">Fertilizer plan</a>
          <a href="#" class="step__link" ref="5" :class="{ here: this.$parent.step == 5 }" @click="goTo(5)">Crop planning</a>
          <h2><b>Post harvest</b></h2>
          <a href="#" class="step__link" ref="6" :class="{ here: this.$parent.step == 6 }" @click="goTo(6)">Fertilizer applied</a>
          <a href="#" class="step__link" ref="7" :class="{ here: this.$parent.step == 7 }" @click="goTo(7)">Harvest</a>
          <a href="#" class="step__link" ref="8" :class="{ here: this.$parent.step == 8 }" @click="goTo(8)">Post harvest assessment</a>
        </div>
        <div class="nav__bottom__background">
          <div @click="toggleStepsNav" class="steps__nav__toggle">
            <img src="@/assets/img/x.svg" alt="">
          </div>
        </div>
      </div>
    </transition>

    <div @click="toggleStepsNav" v-if="!stepsNavOpen" class="steps__nav__toggle fab">
      <img src="@/assets/img/menu.svg" alt="">
    </div>
  </aside>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      stepsNavOpen: false,
    }
  },
  props: {
    title: '',
  },
  computed: {
    ...mapGetters("nfrecord", [
      'record',
      'tracked_nutrients',
      'record_data_pre',
      'n_soil_pre',
    ]),
    ...mapGetters([]),
    nextDisabled() {
      if(this.$parent.step == 1){
        if(this.tracked_nutrients.length == 0) {
          return true;
        }
      }
      if(this.$parent.step == 2){
        if(!this.record.field_id) {
          return true;
        }
        let record_data = this.record_data_pre.find(e => e.soil_or_residue == 0);
        let field_data = this.n_soil_pre;
        if(record_data != field_data) {
          return true;
        }
      }
      if(this.$parent.step == 3){
        if(!this.record.crop_id || !this.record.target_yield) {
          return true;
        }
      }
      if(this.$parent.step == 4) {
        if(!this.record.planning_confirmed) {
          return true;
        }
      }
      if(this.$parent.step == 6) {
        if(!this.record.fertilizers_applied) {
          return true;
        }
      }
      if(this.$parent.step == 7) {
        if(!this.stepHarvestComplete) {
          return true;
        }
      }











      return false
    },
    previousDisabled () {
      if(this.$parent.step == 1) {
        return true;
      }
    }
  },
  methods: {
    goTo(stepNr) {
      this.$parent.step = stepNr;
      this.stepsNavOpen = false;
      // if(stepNr == 1) {
        // this.$parent.step1();
      // }
    },
    toggleStepsNav () {
      // Toggle the menu
      this.stepsNavOpen = !this.stepsNavOpen;

      if(this.stepsNavOpen) {
        var refs = this.$refs;
        var parent = this.$parent;

        setTimeout(function(){
          let Top = refs[parent.step].getBoundingClientRect().top + window.pageYOffset - (window.screen.height / 2 - 20);
          refs["nav"].scrollTop = Top;
        }, 50);
      }
    },
    previous() {
      if(this.previousDisabled) {
        return;
      }
      if(this.$parent.step != 1) {
        this.$parent.step--;
      }
    },
    next() {
      if(this.nextDisabled) {
        return;
      }
      if(this.$parent.step != 8) {
        this.$parent.step++;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.steps__nav {
  z-index: 15;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-y: scroll;

  .nav__top__background, .nav__bottom__background {
    position: fixed;
    z-index: 16;
    width: 100%;
    font-size: 24px;
    height: 40vh;
    pointer-events: none;

    div {
      pointer-events: all;
    }
  }

  .nav__top__background {
    background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    top: 0;

    a {
      padding: 40px 43px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        transform: rotate(180deg);
        height: 20px;
        margin-right: 20px;
      }
    }
  }

  .nav__bottom__background {
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    bottom: 0;
  }

  .nav {
    margin: 50vh 0;
    width: 100vw;

    h2 {
      font-size: 40px;
      margin-left: 43px;
      margin-top: 60px;

      &:first-of-type {
        margin-top: -45px;
      }
    }

    .step__link {
      font-size: 30px;
      margin: 15px 0 15px 63px;
      display: block;

      &.here {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.steps__nav__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 16;
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 50px;
  width: 50px;
  font-size: 30px;
  border-radius: 25px;
  background-color: white;

  &.fab {
    -webkit-filter: drop-shadow( 0 5px 7px rgba(0, 0, 0, .3));
    filter: drop-shadow( 0 5px 7px rgba(0, 0, 0, .3));
  }

  img {
    width: 24px;
  }
}
</style>
