import axios from "axios/index";

export default {
  state: {
    records: {},
  },
  getters: {
    records: state => state.records,
  },
  mutations: {
    RETRIEVE_RECORDS(state, records) {
      state.records = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    RETRIEVE_RECORD(state, record) {
      state.record = record;
    },
    SET_RECORD_FERTILIZER_APPLICATION_EXECUTIONS(state, data) {
      state.record.fert_app_executions = data;
    }
  },
  actions: {
    retrieveRecords({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/records")
          .then(response => {
            commit("RETRIEVE_RECORDS", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createRecord({ rootState }) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .post("/records")
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
}
