<template lang="html">
  <div class="drawer">
    <section>
      <h3>Select from history</h3>

      <ul class="option__group">
        <li>
          <button type="button" @click="cancel()">
            <img class="icon rotated180" src="@/assets/img/next.svg" alt="">
            Exit
          </button>
        </li>
      </ul>

      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="item in this.field_data[this.selectFieldDataModal.nutrient][this.selectFieldDataModal.sor]"
          :key="item.id"
          @click="clickData(item.id)"
          :class="[{ open: selectedId==item.id}, { initialized: pageRendered }]"
          class="accordion__item">
            <div class="top" :class="{ open: selectedId == item.id}">
              <span class="date">{{ item.date }}</span>
              <span class="amount">{{ parseFloat(item.amount) }} Kg {{ item.nutrient}}/ha</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: selectedId==item.id }" @click="clickData(item.id)">
            </div>
            <div class="content" :class="{ open: selectedId == item.id}">
              <small><b>Test type:</b></small>
              <p>{{ item.test_type }}</p>
              <small><b>Additional info:</b></small>
              <p>{{ item.additional_info }}</p>
            </div>
          </li>
        </transition-group>
      </ul>

      <ul class="option__group">
        <li><button @click="cancel()" type="button" name="button">Cancel</button></li>
        <li><button @click="select()" type="submit" :disabled="!selectedId">Use</button></li>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  created() {
    if(this.pre_or_post) {
      this.selectedId = this.picked_field_data_post[this.selectFieldDataModal.nutrient][this.selectFieldDataModal.sor].id;
    } else {
      this.selectedId = this.picked_field_data[this.selectFieldDataModal.nutrient][this.selectFieldDataModal.sor].id;
    }
    this.$store.commit("SET_SHADER", true);
  },
  destroyed() {
    this.$store.commit("SET_SHADER", false);
  },
  data() {
    return {
      selectedId: null,
      pageRendered: false,
    }
  },
  props: {
    pre_or_post: '',
  },
  computed: {
    ...mapGetters(['selectFieldDataModal', 'field_data', 'picked_field_data', 'picked_field_data_post']),
  },
  methods: {
    clickData(id) {
      this.selectedId = id;
    },
    cancel() {
      this.$store.commit("SET_SHOW_SELECT_FIELD_DATA_MODAL", null);
    },
    select() {
      this.$emit('select', this.selectFieldDataModal.sor, this.selectedId);
      this.$store.commit("SET_SHOW_SELECT_FIELD_DATA_MODAL", null);
    }
  }
}
</script>
