<template lang="html">
  <div class="drawer">
    <section>
      <h3>New fertilizer application execution</h3>

      <NutrientProgressBar
        :key="'N'"
        :nutrient="nutrients.find(e => e.symbol == 'N')"
        :preAmount="n_residue_pre.amount"
        :plannedAmountApplied="proposedAddedFertAmounts['N']"
        :targetAmount="targetYieldRecommendedNitrogenPerHectare"
        config="big"
      ></NutrientProgressBar>

      <ul class="dynamic__form">
        <li>
          <label for="fertAppFertId">Fertilizer</label>
          <div class="select__and__add">
            <select class="" id="fertAppFertId" v-model="form.fert_id" @change="rate = null"
             @blur="[$store.commit('REMOVE_ERROR', 'fert_id'), $forceUpdate()]"
             :class="{ error: errors.fert_id }">
              <option v-bind:value="fert.id" v-for="(fert, index) in fertilizers">{{ fert.name }}</option>
            </select>
            <img src="@/assets/img/dropdown-arrow.svg" class="arrow">
            <button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_PREFERENCES', true)">
              <img src="@/assets/img/gear.svg">
            </button>
          </div>
          <div class="validation__error" v-if="errors.fert_id">{{ errors.fert_id[0] }}</div>
        </li>
        <li>
          <ul class="bundeled__buttons">
            <li v-for="nutrient in tracked_nutrients" :key="nutrient.symbol">
              <button
                type="button"
                @click="calculateEnough(nutrient.symbol)"
                :disabled="!form.fert_id">
                <!-- <img src="@/assets/img/top-up-arrow.svg" alt=""> -->
                <!-- <b>{{ nutrient.symbol }}</b> -->
                Top up Nitrogen
               </button>
            </li>
          </ul>
        </li>
        <li class="short">
          <label for="fertAppRate">Rate</label>
          <input type="number" id="fertAppRate" v-model="form.rate"
          min="0" max="99999"
          @blur="[$store.commit('REMOVE_ERROR', 'rate'), $forceUpdate()]"
          :class="{ error: errors.rate }">
          <span class="unit">Kg/ha</span>
          <div class="validation__error" v-if="errors.rate"> {{ errors.rate[0] }}</div>
        </li>
        <li>
          <label for="fertAppDate">Date</label>
          <input type="date" id="fertAppDate" v-model="form.date"
          @blur="[$store.commit('REMOVE_ERROR', 'date'), $forceUpdate()]"
          :class="{ error: errors.date }">
          <div class="validation__error" v-if="errors.date"> {{ errors.date[0] }}</div>
        </li>
      </ul>

      <ul class="option__group">
        <li><button type="button" @click="closeModal">Close</button></li>
        <li><button type="submit" @click="submitNewFertilizerApplicationExecutionModal">Submit</button></li>
      </ul>
    </section>

    <transition name="right-drawer">
      <FertilizerPreferences
        v-if="showFertilizerPreferences"
      ></FertilizerPreferences>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import NutrientProgressBar from './NutrientProgressBar';
import FertilizerPreferences from '../preferences/FertilizerPreferences';

export default {
  data() {
    return {
      form: {
        fert_id: '',
        rate: '',
        date: '',
        record_id: this.record_id,
        field_id: this.field_id,
        planned: false,
      },
      fertilizerForm: {
        name: '',
        N: '',
        P: '',
        K: '',
        S: '',
      },
    }
  },
  components: {
    NutrientProgressBar,
    FertilizerPreferences,
  },
  computed: {
    ...mapGetters([
      'fertilizers',
      'errors',
      'nutrients',
      'showFertilizerPreferences',
    ]),
    ...mapGetters("nfrecord",[
      'record',
      'pickedField',
      'pickedCrop',
      'tracked_nutrients',
      'appliedFertilizerExecutionsAmounts',
      'n_residue_pre',
      'targetYieldRecommendedNitrogenPerHectare',
    ]),
    proposedAddedFertAmounts () {
      var obj = {};

      for(var data in this.appliedFertilizerExecutionsAmounts) {
        var fert = this.appliedFertilizerExecutionsAmounts[data];

        if(this.form.fert_id){
          fert = fert + this.fertilizers.find(e => e.id == this.form.fert_id)[data] * this.form.rate / 100;
        }

        obj[data] = Math.round(parseFloat(fert));
      }

      return obj;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("CLOSE_ALL_MODALS");
    },
    submitNewFertilizerApplicationExecutionModal () {
      let data = this.form;
      data.record_id = this.record.id;
      data.field_id = this.record.field_id;
      console.log(data);
      this.$store.dispatch("storeNewFertilizerApplicationExecution",
        data
      ).then(response => {
        this.$emit("newFertilizerApplicationExecutionCreated", response.data);
        this.$store.commit("CLOSE_ALL_MODALS");
      }).catch(error => {
        this.$store.commit("SET_ERRORS", error.response.data.errors);
        console.log(error.response.data.errors);
      });
    },
    openFertilizerFormModal () {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", true);
    },
    newFertilizerCreated (id) {
      this.form.fert_id = id;
    },
    calculateEnough(nutrient) {
      var goal = this.targetYieldRecommendedNitrogenPerHectare;
      var fertPercentage = this.fertilizers.find(e => e.id == this.form.fert_id)[nutrient];
      var alreadyPlanned = this.appliedFertilizerExecutionsAmounts[nutrient];
      var alreadyAvailable = this.n_residue_pre.amount;
      var recommended = (goal - alreadyPlanned - alreadyAvailable) / fertPercentage * 100;

      if(recommended <= 0) {
        this.form.rate = 0;
      } else {
        this.form.rate = ""+parseInt((goal - alreadyPlanned - alreadyAvailable) / fertPercentage * 100);
      }
    },
  }
}
</script>
