<template>
  <section class="setup__page">
    <!-- All slide-from-left-fade transitions -->
    <!-- <transition name="slide-from-left-fade" mode="out-in"> -->
      <h1 v-if="step === 0">Welcome...</h1>
      <!--TODO - insert name of user to make it personal, make the sentences more like I am speaking to someone, make all buttons appear at the same height for convenience-->
    <!-- </transition> -->
    <!-- <transition name="slide-from-left-fade" mode="out-in"> -->
      <h1 v-if="step === 1">Company name</h1>
    <!-- </transition> -->
    <!-- <transition name="slide-from-left-fade" mode="out-in"> -->
      <h1 v-if="step === 2">Farm name</h1>
    <!-- </transition> -->
    <!-- <transition name="slide-from-left-fade" mode="out-in"> -->
      <h1 v-if="step === 3">Final step</h1>
    <!-- </transition> -->

    <!-- All fade transitions -->
    <!-- <transition name="fade" mode="out-in"> -->
      <div v-if="step === 0" class="instructions" >
        <p>Let's set up your account</p>
        <ul class="option__group">
          <li>
            <button type="submit" @click="this.step++">Next</button>
          </li>
        </ul>
      </div>
    <!-- </transition> -->
    <!-- <transition name="fade" mode="out-in"> -->
      <div v-if="step === 1" class="instructions">
        <p>
          Set up your company name
        </p>
        <ul class="dynamic__form">
          <li><input
            v-model="form.company"
            type="text"
            name="company"

            placeholder="Company name"
          /></li>
        </ul>
        <ul class="option__group">
          <li>
            <button type="submit" :disabled="form.company == ''" @click="submitCompany">Next</button>
          </li>
        </ul>
      </div>
    <!-- </transition> -->
    <!-- <transition name="fade" mode="out-in"> -->
      <div v-if="step === 2" class="instructions">
        <p>
          Set your farm name.
        </p>
        <ul class="dynamic__form">
          <li><input
            v-model="form.farm"
            type="text"
            name="farm"
            placeholder="Farm name"
          /></li>
        </ul>
        <ul class="option__group">
          <li><button type="button" @click="step--">Previous</button></li>
          <li><button type="submit" :disabled="form.farm == ''" @click="submitFarm">Next</button></li>
        </ul>
      </div>
    <!-- </transition> -->
    <!-- <transition name="fade" mode="out-in"> -->
      <div v-if="step === 3" class="instructions">
        <p>
          Great! Your account is set up for use...<br />

          - INSERT SOME WELCOMING TEXT HERE -
        </p>
        <div class="form__control">
          <button type="submit"
            @click="finishSetup"
            class="w--80 primary"
          >
            Start
          </button>
        </div>
      </div>
    <!-- </transition> -->
    <router-link :to="{ name: 'logout', params: {} }">Logout</router-link>
  </section>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  name: "Setup",
  data() {
    return {
      step: null,
      form: {
        company: '',
        farm: '',
      },
      disableNextButton: false,
      disablePreviousButton: false,
    };
  },
  computed: {
    ...mapGetters(["loading", "company", "farm"])
  },
  created() {
    this.$store.dispatch("retrieveSetup")
      .then(response => {
        if(!response.company) {
          this.step = 1;
        } else {
          this.companyName = response.company.name;
          if(!response.farm) {
            this.step = 2;
          } else {
            this.$router.push({ name: "records" });
          }
        }
      })
      .catch(error => {
        console.log(error)
      });
  },
  methods: {
    submitCompany() {
      this.disableNextButton = true;
      this.disablePreviousButton = true;

      if(this.$store.getters.company) {
        var functionName = "updateCompany";
        var functionArg = this.$store.getters.company;
      }else {
        var functionName = "storeCompany";
      }
      this.$store
        .dispatch(functionName, this.form.company)
        .then(() => {
          this.step++;
          this.disableNextButton = false;
          this.disablePreviousButton = false;
        })
        .catch(error => {
          this.disableNextButton = false;
          this.disablePreviousButton = false;
          console.error(error);
          this.errors.company = "Fill in a valid comppany name";
        });

    },

    submitFarm() {
      this.disableNextButton = true;
      this.disablePreviousButton = true;
      this.$store
        .dispatch("storeFarm", this.form.farm)
        .then(() => {
          this.step++;
          this.disableNextButton = false;
          this.disablePreviousButton = false;
        })
        .catch(error => {
          console.error(error);
          this.disableNextButton = false;
          this.disablePreviousButton = false;
          this.errors.farm = "Fill in a valid farm name";
        });
    },

    finishSetup() {
      // show loader TODO
      // this.loading = true;
      this.$router.push({ name: "records"});
    },
  },
};
</script>
