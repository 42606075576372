<template>
  <div class="drawer">
    <section>
      <h3>Crop preferences</h3>

      <ul class="option__group">
        <li>
          <button type="button" @click="$store.commit('SET_SHOW_CROP_PREFERENCES', false)">
            <img class="icon rotated180" src="@/assets/img/next.svg" alt="">
            Exit
          </button>
        </li>
        <li><button type="button" @click="[showCrop = null, $store.commit('SET_SHOW_CROP_FORM', true)]">Create new</button></li>
      </ul>

      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(crop, key) in crops" :key="key" class="accordion__item" :class="[{ open: showCrop==crop.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showCrop == crop.id}">
              {{ crop.name }} <span v-if="crop.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showCrop==crop.id }" @click="clickCrop(crop.id)">
            </div>
            <div class="content" :class="{ open: showCrop == crop.id}">
              <ul>
                <!-- <li>{{ crop.area }}</li> -->
                <!-- <li>{{ crop }}</li> -->
              </ul>

              <ul class="option__group" v-if="crop.company_id != null">
                <li><button type="button" @click="showConfirmationPrompt(crop)">Archive</button></li>
                <!-- <li><button type="button">Show</button></li> -->
                <li><button type="button" @click="$store.commit('SET_SHOW_CROP_FORM', true)">Edit</button></li>
              </ul>
              <ul class="option__group" v-else>
                <!-- <li><button type="button">Archive</button></li> -->
                <li><button type="button" disabled="disabled">Show</button></li>
                <!-- <li><button type="button" @click="$store.commit('SET_SHOW_CROP_FORM', true)">Edit</button></li> -->
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <b>Archived crops:</b>
      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(crop, key) in archivedCrops" :key="key" class="accordion__item" :class="[{ open: showCrop==crop.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showCrop == crop.id}">
              Archived:
              {{ crop.name }} <span v-if="crop.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showCrop==crop.id }" @click="clickCrop(crop.id)">
            </div>
            <div class="content" :class="{ open: showCrop == crop.id}">
              <ul>
                <!-- <li>{{ crop.area }}</li> -->
                <!-- <li>{{ crop }}</li> -->
              </ul>
              <ul class="option__group">
                <li><button type="button" @click="showRestoreConfirmationPrompt(crop)">Restore</button></li>
                <li><button type="button" disabled="disabled">Show</button></li>
                <!-- <li><button type="button" @click="$store.commit('SET_SHOW_CROP_FORM', true)">Edit</button></li> -->
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <ul class="option__group">
        <li><button type="button" @click="$store.commit('SET_SHOW_CROP_PREFERENCES', false)">Exit</button></li>
      </ul>
    </section>

    <transition name="right-drawer">
      <CropForm
        v-if="showCropForm"
        :data="crops.find(e => e.id == showCrop)"
        @newId="newCropCreated"
      ></CropForm>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CropForm from './CropForm';

export default {
  name: "CropPreferences",
  components: {
    CropForm,
  },
  data() {
    return {
      showCrop: null,
      pageRendered: false,
    };
  },
  created() {
    this.pageRendered = true;
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters('nfrecord', ['record']),
    ...mapGetters(['crops', 'archivedCrops', 'showCropForm']),
  },
  methods: {
    clickCrop(id) {
      if(this.showCrop == id) {
        this.showCrop = null;
      } else {
        this.showCrop = id;
      }
    },
    newCropCreated(id) {
      console.log(id);
      // this.showCrop = id;
    },
    showConfirmationPrompt(crop) {
      if(crop.id == this.record.crop_id) {
        alert('This crop is in use! \n \nSelect a different crop for the opened record in order to archive this crop...');
        return;
      }
      let answer = confirm('Are you sure that you want to archive this crop? \n \n' + crop.name);
      if(answer) {
        this.$store.dispatch('archiveCrop', crop.id)
        .then(response => {
          this.showCrop = null;
        }).catch(error => {

        });
      }
    },
    showRestoreConfirmationPrompt(crop) {
      let answer = confirm('Are you sure that you want to restore this crop? \n \n The crop will appear in the selectionboxes again. \n \n' + crop.name);
      if(answer) {
        this.$store.dispatch('restoreCrop', crop.id)
        .then(response => {
          this.showCrop = null;
        }).catch(error => {

        });
      }
    },
  }
}
</script>
