<template lang="html">
  <section class="step5 step">

    <h3>Recommendations</h3>

    <NutrientProgressBar
      config="small"
      :key="'N'"
      :nutrient="nutrients.find(e => e.symbol == 'N')"
      :preAmount="n_residue_pre.amount"
      :plannedAmountApplied="appliedFertilizerExecutionsAmounts['N']"
      :targetAmount="targetYieldRecommendedNitrogenPerHectare"
    ></NutrientProgressBar>

    <h3>Confirm your plan</h3>

    <div class="inline__notification" v-if="showExecuteAllButton">
      <div class="">
        <p>Would you like to confirm all planned fertilizer applications?</p>
      </div>
      <ul class="option__group">
        <li><button type="button" @click="hideExecuteAll">Dismiss</button></li>
        <li><button type="submit" class="primary" :disabled="executeAllButtonLoading" :class="{loading :executeAllButtonLoading}" @click="executeAll">Yes</button></li>
      </ul>
    </div>

    <ul class="option__group">
      <li><button type="submit" @click="openNewFertAppExecutedForm">Create new</button></li>
    </ul>

    <ul>
      <transition-group name="fly-to-right">
        <li v-for="execution, index in fertilizer_application_executions" :key="index" class="executed">
          <details>
            <summary>{{ execution.fert.name }} - {{ parseFloat(execution.rate) }} Kg/ha</summary>
            <p>{{ formatDate(execution.date) }}</p>
            <ul class="option__group">
              <li><button type="button" @click="deleteFertilizerApplicationExecution(execution.id, index)" :class="{ loading: deleteButtonsDisabled.indexOf(index) != -1 }">Delete</button></li>
            </ul>
          </details>
        </li>
      </transition-group>
    </ul>

    <nav class="record">
      <SubmitButton
        :status="this.stepFiveSubmitButtonStatus"
        text="Apply"
        @click.native="confirmExecuted()"
        class="next__step">
      </SubmitButton>
    </nav>


  </section>
</template>

<script>
import {mapGetters} from "vuex";
import InlineNotification from '../ui/InlineNotification';
import NutrientProgressBar from './NutrientProgressBar';
import NewFertilizerApplicationExecutionModal from './NewFertilizerApplicationExecutionModal';
import SubmitButton from '../ui/SubmitButton';
import FormatDate from "../../mixins/formatDate";

export default {
  data() {
    return {
      errors: '',
      executeAllButtonLoading: false,
      showExecuteAllButton: true,
      deleteButtonsDisabled: [],
      stepFiveSubmitButtonStatus: 'idle',
    }
  },
  created() {
    for(var plan in this.fertilizer_application_plans) {
      if(this.fertilizer_application_executions.findIndex(e => e.fert_app_plan_id == plan.id) != -1) {
        console.log('found');
      }
    }
  },
  components: {
    InlineNotification,
    NutrientProgressBar,
    SubmitButton,
    NewFertilizerApplicationExecutionModal,
  },
  mixins: [FormatDate],
  computed: {
    ...mapGetters([
      'fertilizers',
      'nutrients',
      'crops',
      'fertilizer_application_executions',
      'fertilizer_application_plans',
      'showNewFertilizerApplicationExecutionModal',
    ]),

    ...mapGetters("nfrecord", [
      'record',
      'pickedCrop',
      'n_residue_pre',
      'appliedFertilizerExecutionsAmounts',
      'targetYieldRecommendedNitrogenPerHectare',
    ]),
  },
  methods: {
    executeAll() {
      this.executeAllButtonLoading = true;

      let today = this.readableDateToYYYYMMDD(this.formatDate(new Date()));

      let data = [];

      this.fertilizer_application_plans.forEach(e => {
        data.push({
          fert_id: e.fert_id,
          rate: parseInt(e.rate),
          date: today,
          field_id: this.record.field_id,
          record_id: this.record.id,
          fert_app_plan_id: e.id,
        });
      });

      this.$store.dispatch("executeAll", {
        data
      }).then(response => {
        this.executeAllButtonLoading = false;
        this.hideExecuteAll();
        // console.log(response.data);
        console.log(response.data);
        // this.$parent.record.fert_app_executions = response.data;
        // this.$parent.record.fert_app_executions.push(response.data);
        // this.$emit("newFertilizerApplicationExecutionCreated", response.data);
        // this.$parent.showExecuteAllButton = false;
      }).catch(error => {
        this.executeAllButtonLoading = false;
        console.log(error.response.data.errors);
      });
    },
    hideExecuteAll() {
      this.showExecuteAllButton = false;
    },
    openNewFertAppExecutedForm () {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_APPLICATION_EXECUTION_MODAL", true);
      // this.$store.commit("SET_SHADER", true);
    },
    closeFertAppExecutedForm () {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_APPLICATION_MODAL", false);
      this.$store.commit("SET_SHADER", false);
    },
    deleteFertilizerApplicationExecution (id, index) {
      this.deleteButtonsDisabled.push(index);
      let data = {
        id: id,
        index: index
      };
      this.$store.dispatch("deleteFertilizerApplicationExecution", data)
      .then(response => {
        this.deleteButtonsDisabled.splice(this.deleteButtonsDisabled.indexOf(index), 1)
      }).catch(error => {
        this.deleteButtonsDisabled.splice(this.deleteButtonsDisabled.indexOf(index), 1)
        console.log(error);
      });
    },
    confirmExecuted() {
      this.stepFiveSubmitButtonStatus = 'loading';
      //TODO check if everything is filled in => Show notification if no fertilizers have been applied.
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {fertilizers_applied: 1}
      }).then(response => {
        this.stepFiveSubmitButtonStatus = 'idle';
        this.$parent.step++;
      }).catch(error => {
        this.stepFiveSubmitButtonStatus = 'idle';
        console.log(error);
      });
    },
  }
}
</script>
