<template lang="html">
  <section>

    <div class="inline__notification" @click="togglePlanningNotification()" v-if="showPlanningNotification">
      <h4>Please note</h4>
      <div class="expanded" v-if="planningNotificationExpanded">
        <p><small><i>
          This is only a planning of the fertilizer to be applied. In the step after this one, 'Fertilizer applied', you will be confirming what fertilizer has actually been applied. The information in that step will be used in calculations later on.
        </i></small></p>
        <ul class="option__group">
          <li><button type="button" @click="dismissPlanningNotification()">Dismiss</button></li>
        </ul>
      </div>
    </div>

    <h3>Recommendations</h3>

    <!-- 'N' is hard-coded, since no other nutrients are tracked at the moment. Future will change. -->
    <NutrientProgressBar
      :key="'N'"
      :nutrient="nutrients.find(e => e.symbol == 'N')"
      :preAmount="n_residue_pre.amount"
      :plannedAmountApplied="proposedAddedFertAmounts['N']"
      :targetAmount="targetYieldRecommendedNitrogenPerHectare"
      config="small"
    ></NutrientProgressBar>
    <h3>Create a plan</h3>

    <ul class="option__group center">
      <li><button type="submit" @click="openNewFertAppPlanningForm">Create a new application</button></li>
    </ul>

    <h3>Current plan</h3>

    <p v-if="!fertilizer_application_plans.length" class="placeholder">&#183; &#183; &#183; No plan &#183; &#183; &#183;</p>
    <transition-group name="fly-to-right">
      <details v-for="(plan, index) in fertilizer_application_plans" :key="index">
        <!-- <summary>{{ plan.id }}, {{ index }}</summary> -->
        <summary>{{ plan.fert.name }} <span v-if="plan.fert.new_id">[outdated]</span>  - {{ parseFloat(plan.rate) }} Kg/ha</summary>
        <p>Total amount needed: {{ parseFloat(plan.rate * pickedField.area).toFixed(0) }} Kg</p>
        <p>Area: {{ parseFloat(pickedField.area) }} ha</p>
        <!-- TODO Show how much of each tracked nutrient is added with this run. -->
        <ul class="option__group">
          <li><button type="button" @click="deleteFertilizerApplicationPlan(plan.id, index)" :class="{ loading: deleteButtonsDisabled.indexOf(index) != -1 }">Delete</button></li>
        </ul>
      </details>
    </transition-group>

    <nav class="record">
      <SubmitButton
        :status="this.stepFourSubmitButtonStatus"
        text="Confirm"
        @click.native="confirmPlanning()"
        class="next__step">
      </SubmitButton>
    </nav>
    <!--

    <FertilizerFormModal
      :data="this.fertilizerForm"
      v-on:newId="pickFertilizer"
      v-if="showFertilizerFormModal"
    ></FertilizerFormModal> -->

    <!-- <transition name="slide-from-side">
      <aside class="explanation">
        <h1>Hello there!</h1>
      </aside>
    </transition> -->
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

import NutrientProgressBar from './NutrientProgressBar';
import SubmitButton from '../ui/SubmitButton';

export default {
  components: {
    NutrientProgressBar,
    SubmitButton,
  },
  data() {
    return {
      planningNotificationExpanded: false,
      showPlanningNotification: true,
      fertilizerApplicationPlanningForm: {
        recordId: '',
        rate: '',
        fert_id: null,
      },
      fertilizerApplicationPlanningFormLoading: false,
      stepFourSubmitButtonStatus: 'idle',
      fertilizerForm: {
        name: '',
        N: '',
        P: '',
        K: '',
        S: '',
      },
      deleteButtonsDisabled: [],
    }
  },
  created() {
    this.clearFertilizerApplicationPlanningForm();
  },
  computed: {
    ...mapGetters([
      'picked_field_data',
      'nutrients',
      'fertilizers',
      'showFertilizerFormModal',
      'fertilizer_application_plans',
      'fertilizer_application_executions',
    ]),
    ...mapGetters("nfrecord", [
      'record',
      'tracked_nutrients',
      'targetYieldRecommendedNitrogenPerHectare',
      'pickedCrop',
      'pickedField',
      'n_residue_pre',
      'addedFertilizerPlansAmounts',
    ]),
    fertilizerApplicationPlanningFormValid() {
      if(this.fertilizerApplicationPlanningForm.rate == "" ||
        this.fertilizerApplicationPlanningForm.fert_id == null ||
        this.fertilizerApplicationPlanningFormLoading == true
      ) {
        return false;
      }
      return true;
    },
    proposedAddedFertAmounts() {
      let obj = {};

      for(let data in this.addedFertilizerPlansAmounts) {
        let kilogrammeOfNutrientPerHectare = this.addedFertilizerPlansAmounts[data];

        if(this.fertilizerApplicationPlanningForm.fert_id){
          console.log(this.fertilizerApplicationPlanningForm.fert_id);
          kilogrammeOfNutrientPerHectare = kilogrammeOfNutrientPerHectare + this.fertilizers.find(e => e.id == this.fertilizerApplicationPlanningForm.fert_id)[data] * this.fertilizerApplicationPlanningForm.rate / 100;
        }

        obj[data] = Math.round(parseFloat(kilogrammeOfNutrientPerHectare));
      }

      return obj;
    },
  },
  methods: {
    togglePlanningNotification() {
      this.planningNotificationExpanded = !this.planningNotificationExpanded;
    },
    dismissPlanningNotification() {
      this.showPlanningNotification = false;
    },
    openNewFertAppPlanningForm() {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_APPLICATION_PLAN_FORM", true);
    },
    clearFertilizerApplicationPlanningForm() {
      this.fertilizerApplicationPlanningForm = {
        record_id: this.record.id,
        rate: '',
        fert_id: null,
      };
    },
    submitFertilizerApplicationPlanningForm() {
      console.log({ ...this.fertilizerApplicationPlanningForm});
      this.fertilizerApplicationPlanningFormLoading = true;
      this.$store.dispatch("storeNewFertilizerApplicationPlan", this.fertilizerApplicationPlanningForm)
      .then(response => {
        this.clearFertilizerApplicationPlanningForm();
        this.fertilizerApplicationPlanningFormLoading = false;
      }).catch(error => {
        console.log(error);
        this.fertilizerApplicationPlanningFormLoading = false;
      });
    },
    showNewFertilizerForm() {
      this.fertilizerForm = {};
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", true);
    },
    showEditFertilizerForm() {
      this.fertilizerForm = this.fertilizers.find(e => e.id == this.fertilizerApplicationPlanningForm.fert_id);
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", true);
    },
    cancelFertilizerForm() {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", false);
    },
    pickFertilizer(id) {
      this.fertilizerApplicationPlanningForm.fert_id = id;
    },
    calculateEnough(nutrient) {
      var goal = this.targetYieldRecommendedNitrogenPerHectare;
      var fertPercentage = this.fertilizers.find(e => e.id == this.fertilizerApplicationPlanningForm.fert_id)[nutrient];
      var alreadyPlanned = this.addedFertilizerPlansAmounts[nutrient];
      var alreadyAvailable = this.n_residue_pre.amount;
      var recommended = (goal - alreadyPlanned - alreadyAvailable) / fertPercentage * 100;

      if(recommended <= 0) {
        this.fertilizerApplicationPlanningForm.rate = 0;
      } else {
        this.fertilizerApplicationPlanningForm.rate = ""+parseInt((goal - alreadyPlanned - alreadyAvailable) / fertPercentage * 100);
      }
      this.$forceUpdate();
    },
    deleteFertilizerApplicationPlan (id, index) {
      this.deleteButtonsDisabled.push(index);
      let data = {
        id: id,
        index: index
      };
      this.$store.dispatch("deleteFertilizerApplicationPlan", data)
      .then(response => {
        this.deleteButtonsDisabled.splice(this.deleteButtonsDisabled.indexOf(index), 1)
      }).catch(error => {
        this.deleteButtonsDisabled.splice(this.deleteButtonsDisabled.indexOf(index), 1)
        console.log(error);
      });
    },
    confirmPlanning () {
      this.stepFourSubmitButtonStatus = 'loading';
      //TODO check if everything is filled in.
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {planning_confirmed: 1}
      }).then(response => {
        this.stepFourSubmitButtonStatus = 'idle';
        this.$parent.step++;
        this.showExecuteAllButton = true;
      }).catch(error => {
        this.stepFourSubmitButtonStatus = 'idle';
        console.log(error);
      });
    },
  }
}
</script>
