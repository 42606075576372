import axios from "axios";

export default {
  state: {
    nutrients: {}
  },

  getters: {
    nutrients: state => state.nutrients
  },

  mutations: {
    SET_NUTRIENTS(state, nutrients) {
      state.nutrients = nutrients;
    },
    RETRIEVE_NUTRIENTS(state, nutrients) {
      state.nutrients = nutrients;
    },
  },

  actions: {
    retrieve_nutrients({ commit}) {
      axios
        .get("/nutrients")
        .then(response => {
          // Commit a mutation in local store
          commit("RETRIEVE_NUTRIENTS", response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
}
