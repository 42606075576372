<template>
  <div>
    <active-record
      class="active"
      v-for="(record, index) in records"
      :key="record.id"
      :record="record"
      :index="index"
    >
    </active-record>
    <div class="form__control">
      <button @click="createRecord" class="primary">Create a record</button>
    </div>
    <br>
  </div>
</template>

<script>
import ActiveRecord from "./ActiveRecord";
import {mapGetters} from "vuex";

export default {
  name: "RecordList",
  components: {
    ActiveRecord
  },
  data() {
    return {
    }
  },
  created() {
    this.$store.dispatch("retrieveRecords");
    this.$store.dispatch("retrieveAccountInformation");
  },
  computed: {
    ...mapGetters(["records", 'nfrecord/record']),
  },
  methods: {
    createRecord() {
      this.$store.dispatch('createRecord')
      .then(response => {
        console.log(response.data.id);
        this.$router.push({
          path: "/records/" + response.data.id,
          params: response.data
        });
      })
      .catch(error => {
        console.error(error);
      });
    },
  }
};
</script>
