<template lang="html">
  <div class="drawer">
    <section>

      <h3>New Fertilizer</h3>

      <ul class="dynamic__form">
        <li>
          <label for="">Name:</label>
          <input type="text" v-model="form.name" class="with__prefix"
          :class="{ error: errors.name }"
          @blur="[$store.commit('REMOVE_ERROR', 'name'), $forceUpdate()]">
          <span class="prefix">Custom</span>
          <div class="validation__error" v-if="errors.name">{{ errors.name[0] }}</div>
        </li>
        <li>
          <label for="">% N:</label>
          <input type="number" v-model="form.N" max="100" placeholder="Max: 100"
          :class="{ error: errors.N }"
          @blur="[$store.commit('REMOVE_ERROR', 'N'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.N">{{ errors.N[0] }}</div>
        </li>
        <li>
          <label for="">% P:</label>
          <input type="number" v-model="form.P" max="100" placeholder="Max: 100"
          :class="{ error: errors.P }"
          @blur="[$store.commit('REMOVE_ERROR', 'P'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.P">{{ errors.P[0] }}</div>
        </li>
        <li>
          <label for="">% K:</label>
          <input type="number" v-model="form.K" max="100" placeholder="Max: 100"
          :class="{ error: errors.K }"
          @blur="[$store.commit('REMOVE_ERROR', 'K'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.K">{{ errors.K[0] }}</div>
        </li>
        <li>
          <label for="">% S:</label>
          <input type="number" v-model="form.S" max="100" placeholder="Max: 100"
          :class="{ error: errors.S }"
           @blur="[$store.commit('REMOVE_ERROR', 'S'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.S">{{ errors.S[0] }}</div>
        </li>
      </ul>

      <ul class="option__group" v-if="setting == 'edit'">
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitEditFertilizer">Edit</button>
      </ul>

      <ul class="option__group" v-else>
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitNewFertilizer">Create New</button>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  created() {
    this.$store.commit("RESET_ERRORS");
    if(this.data.name) {
      this.setting = 'edit';
    }
    this.form = JSON.parse(JSON.stringify(this.data));
    this.form.N = parseFloat(this.form.N);
    this.form.P = parseFloat(this.form.P);
    this.form.K = parseFloat(this.form.K);
    this.form.S = parseFloat(this.form.S);
    if(this.form.company_id) {
      this.form.name = this.form.name.slice(7);
    }
  },
  destroyed() {
  },
  data() {
    return {
      form: {},
      setting: '',
      buttonsEnabled: true
    }
  },
  props: {
    data: '',
  },
  computed: {
    ...mapGetters([
      'errors',
      'company',
    ]),
  },
  methods: {
    closeModal() {
      this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", false);
    },
    submitEditFertilizer() {
      this.buttonsEnabled = false;
      console.log(this.form);
      if(this.form.company_id == null) {
        this.submitNewFertilizer();
        return;
      }
      let data = JSON.parse(JSON.stringify(this.form));
      data.name = "CUSTOM " + data.name;
      data.company_id = this.company.id;
      this.$store.dispatch("storeEditFertilizer", data)
      .then(response => {
        this.buttonsEnabled = true;
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        this.$store.commit("SET_ERRORS", error.response.data.errors);
        console.log(error);
      });
    },
    submitNewFertilizer() {
      this.buttonsEnabled = false;
      let data = {
        name: "CUSTOM " + this.form.name,
        N: this.form.N,
        P: this.form.P,
        K: this.form.K,
        S: this.form.S,
        company_id: this.company.id,
      };
      this.$store.dispatch("storeNewFertilizer", data)
      .then(response => {
        this.buttonsEnabled = true;
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_NEW_FERTILIZER_MODAL", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        this.$store.commit("SET_ERRORS", error.response.data.errors);
        console.log(error);
      });
    },
    removeError(field) {
      this.$store.commit("REMOVE_ERROR", field);
      this.$forceUpdate();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
