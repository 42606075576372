<template>
  <div class="drawer">
    <section>
      <h3>Field preferences</h3>

      <ul class="option__group">
        <li>
          <button type="button" @click="$store.commit('SET_SHOW_FIELD_PREFERENCES', false)">
            <img class="icon rotated180" src="@/assets/img/next.svg" alt="">
            Exit
          </button>
        </li>
        <li><button type="button" @click="[showField = null, $store.commit('SET_SHOW_FIELD_FORM', true)]">Create new</button></li>
      </ul>

      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(field, key) in this.fieldsIncludingOutdated" :key="key" class="accordion__item" :class="[{ open: showField==field.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showField == field.id}">
              {{ field.name }} <span v-if="field.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showField==field.id }" @click="clickField(field.id)">
            </div>
            <div class="content" :class="{ open: showField == field.id}">
              <ul>
                <li>{{ parseFloat(field.area) }}ha</li>
                <li>{{ field.soil_type.name }}</li>
              </ul>

              <ul class="option__group" v-if="!field.new_id">
                <li><button type="button" @click="showConfirmationPrompt(field)">Archive</button></li>
                <!-- <li><button type="button">Show</button></li> -->
                <li><button type="button" @click="$store.commit('SET_SHOW_FIELD_FORM', true)">Edit</button></li>
              </ul>
              <ul class="option__group" v-else>
                <!-- <li><button type="button">Archive</button></li> -->
                <!-- <li><button type="button">Show</button></li> -->
                <li><button type="button" @click="$store.commit('SET_SHOW_FIELD_FORM', true)" disabled="disabled">Update</button></li>
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <b>Archived fields:</b>
      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(field, key) in archivedFields" :key="key" class="accordion__item" :class="[{ open: showField==field.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showField == field.id}">
              Archived:
              {{ field.name }} <span v-if="field.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showField==field.id }" @click="clickField(field.id)">
            </div>
            <div class="content" :class="{ open: showField == field.id}">
              <ul>
                <li>{{ parseFloat(field.area) }}ha</li>
                <li>{{ field.soil_type.name }}</li>
              </ul>
              <ul class="option__group">
                <li><button type="button" @click="showRestoreConfirmationPrompt(field)">Restore</button></li>
                <li><button type="button" disabled="disabled">Show</button></li>
                <!-- <li><button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_FORM', true)">Edit</button></li> -->
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <ul class="option__group">
        <li><button type="button" @click="$store.commit('SET_SHOW_FIELD_PREFERENCES', false)">Exit</button></li>
      </ul>
    </section>

    <transition name="right-drawer">
      <FieldForm
        v-if="showFieldForm"
        :data="fields.find(e => e.id == showField)"
        @newId="newFieldCreated"
      ></FieldForm>
    </transition>

    <!-- <transition name="confirmation-prompt">

    </transition> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FieldForm from './FieldForm';

export default {
  name: "FieldPreferences",
  components: {
    FieldForm,
  },
  data() {
    return {
      showField: null,
      pageRendered: false,
    };
  },
  created() {
    this.pageRendered = true;
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters("nfrecord", ['record']),
    ...mapGetters(["fields", 'archivedFields', 'outDatedFields', 'fieldsIncludingOutdated', 'showFieldForm']),
  },
  methods: {
    clickField(id) {
      if(this.showField == id) {
        this.showField = null;
      } else {
        this.showField = id;
      }
    },
    newFieldCreated(id) {
      this.$forceUpdate();
      console.log(id);
      this.showField = id;
    },
    showConfirmationPrompt(field) {
      if(field.id == this.record.field_id) {
        alert('This field is in use! \n \nSelect a different field for the opened record in order to archive this field...');
        return;
      }
      let answer = confirm('Are you sure that you want to archive this field? \n \n' + field.name);
      if(answer) {
        this.$store.dispatch('deleteField', field.id)
        .then(response => {
          this.showField = null;
        }).catch(error => {

        });
      }
    },
    showRestoreConfirmationPrompt(field) {
      let answer = confirm('Are you sure that you want to restore this field? \n \n The field will appear in the selectionboxes again. \n \n' + field.name);
      if(answer) {
        this.$store.dispatch('restoreField', field.id)
        .then(response => {
          this.showField = null;
        }).catch(error => {

        });
      }
    },
  }
}
</script>
