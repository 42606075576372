<template lang="html">
  <section class="container step">

    <h3>Crop selection</h3>


    <ul class="dynamic__form" v-if="!this.showCropForm">

      <li class="select__and__preferences">
        <select v-model="selectedCropId"
          @change="pickCrop"
          :disabled="this.loadingCropSelect">
          <option v-for="crop in cropsWithPickedCrop" :value="crop.id">{{ crop.name }} <span v-if="crop.new_id">[outdated]</span> </option>
          <!-- <option :value="pickedCrop.id" v-if="crops.findIndex(e => e.id == pickedCrop.id) == -1">{{ pickedCrop.name }}</option> -->
        </select>
        <img src="@/assets/img/dropdown-arrow.svg" class="arrow" alt="">
        <button type="button" @click="$store.commit('SET_SHOW_CROP_PREFERENCES', true)">
          <img src="@/assets/img/gear.svg" alt="">
        </button>
      </li>

      <div v-if="pickedCrop"> <!--   <- This is just for preventing an error when still loading data -->
        <div class="inline__notification" v-if="pickedCrop.new_id && showDeprecatedCropNotification">
          <div class="top" @click="toggleDeprecatedCropNotificationExpansion()">
            <h4>Deprecated crop</h4>
          </div>
          <div class="expanded" v-if="deprecatedCropNotificationExpanded">
            <p><small><i>
              The chosen crop has been updated. Would you like to update the crop to the new data?
            </i></small></p>
            <ul class="option__group">
              <li><button type="button" @click="showDeprecatedCropNotification = !showDeprecatedCropNotification">Dismiss</button></li>
              <li><button type="submit" @click="[selectedCropId = pickedCrop.new_id, pickCrop(), toggleDeprecatedCropNotificationExpansion()]">Update</button></li>
            </ul>
          </div>
        </div>
      </div>

      <li class="very_short">
        <label for="targetYieldForm">Target Yield</label>
        <input
          type="number"
          :disabled="itemsWhichAreLoading.indexOf('target_yield') > -1 && !errors.target_yield"
          id="targetYieldForm"
          v-model="targetYieldForm"
          :class='{ error: errors.target_yield }'
          @blur="updateTargetYield()"
          @focus="targetYieldCache = JSON.parse(JSON.stringify(targetYieldForm))"
        >
        <span class="unit">t/ha</span>
        <div class="validation__error" v-if="errors.target_yield">
          {{ errors.target_yield[0] }}
        </div>
      </li>
    </ul>

    <h3>Recommendation <img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'n_recommendation')"></h3>
    <p class="calculated__number">Nitrogen recommended: <span class="big"> {{ targetYieldRecommendedNitrogenPerHectare }} </span> Kg N/ha</p>
    <!-- <span v-if="pickedCrop && n_soil_pre">Nitrogen recommended: <b>{{ targetYieldRecommendedNitrogenPerHectare }} </b>kg N/ha</span> -->

    <!-- <form v-on:submit.prevent v-if="this.showCropForm">
      <ul class="dynamic__form">
        <li>
          <label for="name"><small>Name</small></label>
          <input type="text"
            @change="validate()"
            v-model="cropForm.name"
            required
            :disabled="(formStatus=='disabled')"
            :class="{with__prefix: (formStatus=='edit' || formStatus=='new') }"/>
            <span class="prefix">CUSTOM</span>
        </li>
        <li>
          <label for="growth_period"><small>Growth period</small></label>
          <input id="growth_period" type="number" @change="validate()" v-model="cropForm.growth_period" required :disabled="formStatus=='disabled'">
          <span class="unit">days</span>
        </li>
        <li>
          <label for="N"><small>N (Kg/ha) required per yield (T/ha)</small></label>
          <input id="N" type="number" @change="validate()" v-model="cropForm.N" required :disabled="formStatus=='disabled'">
          <span class="unit">Kg N/ha</span>
        </li>
      </ul>
      <ul class="option__group" v-if="formStatus=='new'">
        <li><button type="button" @click="cancelForm()" :disabled="formStatus=='disabled'">Cancel</button></li>
        <li><button type="submit" @click="submitNew()" :disabled="!formValid">Save new</button></li>
      </ul>
      </ul>
      <ul class="option__group" v-if="formStatus=='edit'">
        <li><button type="button" @click="cancelForm()">Cancel</button></li>
        <li><button type="submit" @click="submitEdit()" :disabled="!formValid">Save edit</button></li>
      </ul>
    </form> -->


    <div v-if="record.crop_id">

      <h3><span>Planning <small>(optional)</small></span> </h3>

      <ul class="dynamic__form" v-if="this.timeFrameLoading == true">
        <li class="notification loading">
          <div class="spinner button-content">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </li>
      </ul>

      <ul class="dynamic__form" v-else>
        <li v-if="!plantingDateIsSet">
          <label for="planting_date">Planting date</label>
          <input type="date" v-model="selectedPlantingDate" id="planting_date" @change="pickPlantingDate()">
        </li>
        <li v-if="this.pickedCrop.growth_period && this.record.planting_date && ((plannedHarvestDateIsSameAsSuggestion && !this.showTimeFrame) || (!this.record.planned_harvest_date))" class="notification">
          <p>The suggested growth time is <b>{{ this.pickedCrop.growth_period }} days</b>, would you like to set the planned harvest date accordingly?</p>
          <ul class="option__group">
            <li><button type="button" @click="pickPlannedHarvestDate(readableDateToYYYYMMDD(plannedHarvestDateSuggestion))">Yes</button></li>
            <li><button type="button" @click="this.showTimeFrame = true;">No, use custom</button></li>
          </ul>
        </li>
        <div v-if="this.pickedCrop.growth_period && !plannedHarvestDateIsSameAsSuggestion && showSuggestion" class="inline__notification" @click="expandSuggestion = true;">
          <h4>Suggestion</h4>
          <div class="expanded" v-if="expandSuggestion">
            <p><small><i>Your picked crop has a recommended growth period of <b>{{ this.pickedCrop.growth_period }} days</b>, but your planned harvest date is <b>{{ calculatedDays }} days</b>. Would you like to set the planned harvest date accordingly?</i></small></p>
            <ul class="option__group">
              <li><button type="button" @click="pickPlannedHarvestDate(readableDateToYYYYMMDD(plannedHarvestDateSuggestion));">Yes</button></li>
              <li><button type="button" @click="showTimeFrame = true; showSuggestion = false; expandSuggestion = false;">Dismiss</button></li>
            </ul>
          </div>
        </div>
        <li v-if="this.showTimeFrame || plantingDateIsSet" class="time__frame">
          <div class="row">
            <label for="planting_date2">
              <small>Planting date</small>
              <input id="planting_date2" type="date" v-model="selectedPlantingDate" @change="pickPlantingDate()">
            </label>
            <label for="planned_harvest_date">
              <small>Planned harvest date</small>
              <input id="planned_harvest_date" type="date" v-model="selectedPlannedHarvestDate" @change="pickPlannedHarvestDate(selectedPlannedHarvestDate)">
            </label>
          </div>
          <div class="bar">
            <img src="@/assets/img/radio-default-filled.svg" alt="">
            <img src="@/assets/img/radio-default-filled.svg" alt="">
            <p v-if="this.record.planned_harvest_date">&#183; &#183; &#183; {{ calculatedDays }} days &#183; &#183; &#183;</p>
            <p v-else>Please plan in a harvest date</p>
          </div>
        </li>
      </ul>

      <nav class="record">
        <button
          type="submit"
          @click="nextStep()"
          class="next__step primary"
          :disabled="stepThreeSubmitButtonIsDisabled">
          <b>Next step</b>
        </button>
      </nav>
    </div>

    <transition name="right-drawer">
      <CropPreferences
        v-if="showCropPreferences">
      </CropPreferences>
    </transition>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import FormatDate from "../../mixins/formatDate";
import CropPreferences from "../preferences/CropPreferences";

export default {
  mixins: [FormatDate],
  components: {
    CropPreferences,
  },
  data() {
    return {
      showCropForm: false,
      formStatus: 'disabled',
      cropForm: {},
      formValid: false,
      targetYieldForm: null,
      targetYieldCache: null,
      selectedCropId: '',
      loadingCropSelect: false,
      selectedPlantingDate: null,
      showTimeFrame: false,
      timeFrameLoading: false,
      selectedPlannedHarvestDate: null,
      showSuggestion: true,
      expandSuggestion: false,
      itemsWhichAreLoading: [],
      showDeprecatedCropNotification: true,
      deprecatedCropNotificationExpanded: false,
    }
  },
  created() {
    if(this.record.crop_id) {
      this.selectedCropId = this.record.crop_id;
      // this.selectedCropGroup = this.allCrops.find(e => e.id == this.pickedCropId).name;
    }
    if(this.record.target_yield) {
      this.targetYieldForm = parseFloat(this.record.target_yield);
    }
    if(this.record.planting_date) {
      this.selectedPlantingDate = this.record.planting_date;
    }
    if(this.record.planned_harvest_date) {
      this.selectedPlannedHarvestDate = this.record.planned_harvest_date;
    }
    if(this.record.planting_date && this.record.planned_harvest_date) {
      this.showTimeFrame = true;
    }
  },
  computed: {
    ...mapGetters('nfrecord', [
      'record',
      'company',
      // 'nitrogenRecommendedPerHectare',
      'pickedCrop',
      'n_soil_pre',
      'targetYieldRecommendedNitrogenPerHectare'
    ]),
    ...mapGetters([
      'cropsWithPickedCrop',
      'nutrients',
      'errors',
      'showCropPreferences',
    ]),
    // filteredCrops: function () {
    //   if(this.selectedCropGroup == null) {
    //     return [];
    //   }
    //   return this.crops[this.selectedCropGroup].sort((a, b) => a.target_yield - b.target_yield);
    // },
    calculatedDays: function () {
      let diffTime = Math.abs(new Date(this.readableDateToMMDDYYYY(this.formatDate(this.record.planned_harvest_date))) - new Date(this.readableDateToMMDDYYYY(this.formatDate(this.record.planting_date))));
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    plantingDateIsSet: function () {
      if (this.record.planting_date) {
        return true;
      }
      return false;
    },
    plannedHarvestDateSuggestion: function () {
      if(!this.record.planting_date || !this.record.crop_id) {
        return false;
      }
      var newDate = new Date(this.record.planting_date);
      newDate.setDate(newDate.getDate() + this.pickedCrop.growth_period);
      newDate = this.formatDate(newDate);
      return newDate;
    },
    plannedHarvestDateIsSameAsSuggestion: function () {
      if(!this.record.planned_harvest_date) {
        return true;
      }
      if(this.record.planned_harvest_date == this.readableDateToYYYYMMDD(this.plannedHarvestDateSuggestion)) {
        return true;
      }
      return false;
    },
    stepThreeSubmitButtonIsDisabled: function () {
      if(!this.record.crop_id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateRecord(newData) {
      let data = {
        id: this.record.id,
        recordData: newData
      };
      this.itemsWhichAreLoading = [];
      // Set UI to loading the appropriate items.
      for(let i in newData){
        this.itemsWhichAreLoading.push(i);
      }

      this.$store.dispatch("nfrecord/updateRecord", data)
      .then(response => {
        for(let i in newData){
          this.itemsWhichAreLoading.splice(i);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    toggleDeprecatedCropNotificationExpansion() {
      console.log('clicked');
      this.deprecatedCropNotificationExpanded = !this.deprecatedCropNotificationExpanded;
    },
    updateTargetYield() {
      this.$store.commit('REMOVE_ERROR', 'target_yield');
      this.$forceUpdate();
      if(this.targetYieldForm == this.targetYieldCache) {
        return;
      }
      this.updateRecord({target_yield: this.targetYieldForm});
    },
    showNewCropForm() {
      this.cropForm = {
        'name': '',
        'growth_period': '',
        'N': '',
      };
      this.showCropForm = true;
      this.formStatus = 'new';
    },
    editCrop() {
      this.showNewCropForm();
      this.populateForm();
      this.formValid = true;
      this.formStatus = 'edit';
    },
    validate() {
      if(this.cropForm.name == '' | this.cropForm.growth_period == '' | this.cropForm.N == '') {
        this.formValid = false;
      } else {
        this.formValid = true;
      }
    },
    populateForm() {
      this.cropForm = null;
      this.cropForm = JSON.parse(JSON.stringify(this.pickedCrop));
      this.cropForm.N = parseFloat(this.cropForm.N);
      if(this.cropForm.company_id) {
        this.cropForm.name = this.cropForm.name.slice(7);
      } else {
        // TODO Let user know he is editing a copy of the original.
      }
    },
    cancelForm() {
      this.showCropForm = false;
      this.formStatus = 'disabled';
    },
    submitNew() {
      // The company_id has to be added, name prefix added, and the id has to be stripped.
      let data = JSON.parse(JSON.stringify(this.cropForm));
      data.name = 'CUSTOM ' + data.name;
      delete data.id;
      data.company_id = this.company.id;
      this.formStatus = 'disabled';
      this.formValid = false;
      // TODO (?) Make it clear to the user so that they know it's automatically stored with that prefix
      this.$store.dispatch("storeNewCrop", data)
      .then(response => {
        let lastCreatedCrop = this.crops.reduce((newest, e) => newest.created_at > e.created_at ? newest : e);
        this.showCropForm = false;
        this.selectedCropId = lastCreatedCrop.id;
        this.pickCrop(lastCreatedCrop.id);
      }).catch(error => {
        this.formStatus = 'new';
        this.validate();
      });
    },
    submitEdit() {
      if(this.cropForm.company_id == null){
        // The data is based off an exising globally available crop
        // Send over to submitNew() in order to keep the globally available crop in the database, untouched.
        this.submitNew();
        return;
      }
      this.formStatus = 'disabled';
      this.formValid = false;
      let data = JSON.parse(JSON.stringify(this.cropForm));
      data.name = 'CUSTOM ' + data.name;
      data.company_id = this.company.id;
      console.log(data);
      this.$store.dispatch("editCrop", data)
      .then(response => {
        let lastCreatedCrop = this.crops.reduce((newest, e) => newest.created_at > e.created_at ? newest : e);
        this.selectedCropId = lastCreatedCrop.id;
        this.pickCrop(lastCreatedCrop.id);
        this.formStatus = 'edit';
        this.showCropForm = false;
      }).catch(error => {
        this.showCropForm = true;
        this.formStatus = 'edit';
        this.validate();
      });
    },
    pickCrop() {
      if(this.selectedCropId == ""){
        return;
      }
      this.loadingCropSelect = true;

      // TODO feedback loading
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {crop_id: this.selectedCropId}
      }).then(response => {
        this.loadingCropSelect = false;
        this.targetYieldForm = parseFloat(this.pickedCrop.standard_marketable_yield);
        this.updateTargetYield();
      })
      .catch(error => {
        this.loadingCropSelect = false;
      });
    },
    pickPlantingDate() {
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {planting_date: this.selectedPlantingDate}
      }).then(response => {
        this.showTimeFrame = true;
      }).catch(error => {
        console.log(error);
      });
    },
    pickPlannedHarvestDate(date) {
      this.timeFrameLoading = true;
      this.showSuggestion = false;
      this.expandSuggestion = false;
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {planned_harvest_date: date}
      }).then(response => {
        this.selectedPlannedHarvestDate = date;
        this.timeFrameLoading = false;
        this.showTimeFrame = true;
      }).catch(error => {
        this.timeFrameLoading = false;
        this.showTimeFrame = true;
        console.log(error);
      });
    },
    nextStep() {
      this.$parent.step++;
    },
  }
}
</script>
