<template>
  <div>This is what the website is about.</div>
</template>

<script>
export default {
  name: "About",
  created() {
  },
};
</script>

<style scoped></style>
