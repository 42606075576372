<template>
  <div class="drawer">
    <section>
      <h3>Fertilizer preferences</h3>

      <ul class="option__group">
        <li>
          <button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_PREFERENCES', false)">
            <img class="icon rotated180" src="@/assets/img/next.svg" alt="">
            Exit
          </button>
        </li>
        <li><button type="button" @click="[showFertilizer = null, $store.commit('SET_SHOW_FERTILIZER_FORM', true)]">Create new</button></li>
      </ul>

      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(fertilizer, key) in fertilizers" :key="key" class="accordion__item" :class="[{ open: showFertilizer==fertilizer.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showFertilizer == fertilizer.id}">
              {{ fertilizer.name }} <span v-if="fertilizer.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showFertilizer==fertilizer.id }" @click="clickFertilizer(fertilizer.id)">
            </div>
            <div class="content" :class="{ open: showFertilizer == fertilizer.id}">
              <ul>
                <li>% N: {{ parseFloat(fertilizer.N) }}</li>
                <li>% P: {{ parseFloat(fertilizer.P) }}</li>
                <li>% K: {{ parseFloat(fertilizer.K) }}</li>
                <li>% S: {{ parseFloat(fertilizer.S) }}</li>
              </ul>

              <ul class="option__group" v-if="fertilizer.company_id != null">
                <li><button type="button" @click="showConfirmationPrompt(fertilizer)">Archive</button></li>
                <!-- <li><button type="button">Show</button></li> -->
                <li><button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_FORM', true)">Edit</button></li>
              </ul>
              <ul class="option__group" v-else>
                <!-- <li><button type="button">Archive</button></li> -->
                <li><button type="button" disabled="disabled">Show</button></li>
                <!-- <li><button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_FORM', true)">Edit</button></li> -->
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <b>Archived fertilizers:</b>
      <ul class="accordion">
        <transition-group name="fly-to-right">
          <li v-for="(fertilizer, key) in archivedFertilizers" :key="key" class="accordion__item" :class="[{ open: showFertilizer==fertilizer.id}, { initialized: pageRendered }]">
            <div class="top" :class="{ open: showFertilizer == fertilizer.id}">
              Archived:
              {{ fertilizer.name }} <span v-if="fertilizer.new_id">[outdated]</span>
              <img src="@/assets/img/next.svg" :class="{ upside_down: showFertilizer==fertilizer.id }" @click="clickFertilizer(fertilizer.id)">
            </div>
            <div class="content" :class="{ open: showFertilizer == fertilizer.id}">
              <ul>
                <li>% N: {{ parseFloat(fertilizer.N) }}</li>
                <li>% P: {{ parseFloat(fertilizer.P) }}</li>
                <li>% K: {{ parseFloat(fertilizer.K) }}</li>
                <li>% S: {{ parseFloat(fertilizer.S) }}</li>
              </ul>
              <ul class="option__group">
                <li><button type="button" @click="showRestoreConfirmationPrompt(fertilizer)">Restore</button></li>
                <li><button type="button" disabled="disabled">Show</button></li>
                <!-- <li><button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_FORM', true)">Edit</button></li> -->
              </ul>
            </div>
          </li>
        </transition-group>
      </ul>

      <ul class="option__group">
        <li><button type="button" @click="$store.commit('SET_SHOW_FERTILIZER_PREFERENCES', false)">Exit</button></li>
      </ul>
    </section>

    <transition name="right-drawer">
      <FertilizerForm
        v-if="showFertilizerForm"
        :data="fertilizers.find(e => e.id == showFertilizer)"
        @newId="newFertilizerCreated"
      ></FertilizerForm>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FertilizerForm from './FertilizerForm';

export default {
  name: "FertilizerPreferences",
  components: {
    FertilizerForm,
  },
  data() {
    return {
      showFertilizer: null,
      pageRendered: false,
    };
  },
  created() {
    this.pageRendered = true;
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters('nfrecord', ['record']),
    ...mapGetters(['fertilizers', 'archivedFertilizers', 'showFertilizerForm']),
  },
  methods: {
    clickFertilizer(id) {
      if(this.showFertilizer == id) {
        this.showFertilizer = null;
      } else {
        this.showFertilizer = id;
      }
    },
    newFertilizerCreated(id) {
      console.log(id);
      // this.showFertilizer = id;
    },
    showConfirmationPrompt(fertilizer) {
      let answer = confirm('Are you sure that you want to archive this fertilizer? \n \n' + fertilizer.name);
      if(answer) {
        this.$store.dispatch('archiveFertilizer', fertilizer.id)
        .then(response => {
          this.showFertilizer = null;
        }).catch(error => {

        });
      }
    },
    showRestoreConfirmationPrompt(fertilizer) {
      let answer = confirm('Are you sure that you want to restore this fertilizer? \n \n The fertilizer will appear in the selectionboxes again. \n \n' + fertilizer.name);
      if(answer) {
        this.$store.dispatch('restoreFertilizer', fertilizer.id)
        .then(response => {
          this.showFertilizer = null;
        }).catch(error => {

        });
      }
    },
  }
}
</script>
