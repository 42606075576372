<template lang="html">
  <section class="step step6">

    <h3>Harvest data</h3>

    <form v-on:submit.prevent>
      <ul class="dynamic__form">
        <li>
          <label for="yield">Yield</label>
          <input id="yield" type="number" v-model="form.yield" min="0" step="0.01">
          <span class="unit">t/ha</span>
        </li>
        <li v-if="tracked_nutrients.find(e => e.symbol == 'N')">
          <label for="n_yield">N in yield</label>
          <input id="n_yield" type="number" v-model="form.n_yield" min="0" step="0.01">
          <span class="unit">Kg N/t</span>
        </li>
        <li>
          <label for="residue">Residue</label>
          <input id="residue" type="number" v-model="form.residue" min="0" step="0.01" @keydown="showAutofillResidueSuggestion = false">
          <span class="unit">t/ha</span>
        </li>
        <transition name="suggestion">
          <li class="suggestion" v-if="showAutofillResidueSuggestion">
            <p>Autofill residue?</p>
            <ul class="option__group">
              <li><button type="button" @click="showAutofillResidueSuggestion = false" :disabled="!autofillButtonsEnabled">Dismiss</button></li>
              <li><button type="submit" @click="autofillResiduePostHarvest" :disabled="!autofillButtonsEnabled">Yes</button></li>
            </ul>
          </li>
        </transition>
      </ul>
      <ul class="option__group">
        <!-- <li><button type="button" @click="clearHarvestData()" :class="{loading: harvestDataFormLoading}">Clear</button></li> -->
        <li><button type="submit" @click="submitHarvestData()" :class="{loading: harvestDataFormLoading}">Submit</button></li>
      </ul>
    </form>
    <!-- TODO Show this number here already? -->
    <p class="calculated__number">Your N exported is: <span class="big"> {{ nitrogenExported }} </span> Kg N/ha</p>

    <div class="nutrients">
      <div class="nutrients__helper">
        <NutrientData
          v-for="(nutrient, index) in this.tracked_nutrients"
          :key="nutrient.symbol"
          :nkey="nutrient.symbol"
          :data="picked_field_data_post"
          :pre_or_post="1"
          :soilPlusResidueAmountsProp="soilPlusResidueAmountsPost"
          >
          <!-- v-on:updated="" -->
        </NutrientData>
      </div>
    </div>


    <nav class="record">
      <SubmitButton
        :status="this.stepSixSubmitButtonStatus"
        text="Submit"
        @click.native="submitStepPostHarvestData()"
        class="next__step">
      </SubmitButton>
    </nav>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

import FormatDate from '../../mixins/formatDate';

import NutrientData from './NutrientData';
import SubmitButton from '../ui/SubmitButton';
export default {
  mixins: [FormatDate],
  components: {
    NutrientData,
    SubmitButton,
  },
  data() {
    return {
      form: {
        yield: '',
        n_yield: '',
        residue: '',
      },
      harvestDataFormLoading: false,
      showAutofillResidueSuggestion: false,
      stepSixSubmitButtonStatus: 'idle',
      autofillButtonsEnabled: true,
    };
  },
  created() {
    if(this.record.yield) {
      this.form.yield = parseFloat(this.record.yield);
    }
    if(this.record.n_yield) {
      this.form.n_yield = parseFloat(this.record.n_yield);
    }
    if(this.record.residue) {
      this.form.residue = parseFloat(this.record.residue);
    }
  },
  computed: {
    ...mapGetters('nfrecord', [
      'record',
      'pickedCrop',
      'tracked_nutrients',
      'nitrogenExported',
      'soilPlusResidueAmountsPost',
    ]),
    ...mapGetters([
      'formatFieldDataRecordPost',
      'picked_field_data_post',
    ]),
  },
  methods: {
    clearHarvestData() {
      this.form = {
        yield: '',
        n_yield: '',
      }
    },
    submitHarvestData() {
      this.harvestDataFormLoading = true;
      let giveAutofillResidueSuggestion = false;
      if(this.record.residue != null && this.form.residue != '' && this.record.residue != this.form.residue && this.pickedCrop.n_content_in_residue != 0) {
        giveAutofillResidueSuggestion = true;
      }
      this.$store.dispatch("nfrecord/updateRecord", {
        id: this.record.id,
        recordData: {
          yield: this.form.yield,
          n_yield: this.form.n_yield,
          residue: this.form.residue,
        }
      }).then(response => {
        this.harvestDataFormLoading = false;
        if(giveAutofillResidueSuggestion) {
          this.showAutofillResidueSuggestion = true;
        }
      }).catch(error => {
        this.harvestDataFormLoading = false;
        console.log(error);
      });
    },
    submitStepPostHarvestData() {
      // Get id's from all the picked field data.
      let data = [];
      for(let i in this.picked_field_data_post) {
        let n = this.picked_field_data_post[i];
        if(n[0].amount) {
          data.push(n[0].id);
          if(n[1].amount) {
            data.push(n[1].id);
          }
        }
      }
      this.stepSixSubmitButtonStatus = 'loading';

      // Send data
      this.$store.dispatch("nfrecord/saveRecordFieldData", {
        pre_or_post: 1,
        recordData: data,
        recordId: this.record.id
      }).then(response => {
        this.stepSixSubmitButtonStatus = 'idle';
        this.$parent.step++;
      }).catch(error => {
        this.stepSixSubmitButtonStatus = 'idle';
        console.log(error);
      });
    },
    autofillResiduePostHarvest() {
      this.autofillButtonsEnabled = false;
      // Nutrient is hardcoded for now
      let calculatedResidueAmountPerHectare = parseFloat(this.pickedCrop.n_content_in_residue * this.record.residue);
      let data = {
        field_id: this.record.field_id,
        nutrient: 'N',
        amount: calculatedResidueAmountPerHectare,
        date: this.readableDateToYYYYMMDD(this.formatDate(new Date())),
        test_type: "Autofill",
        soil_or_residue: 1,
        additional_info: 'This value is based on the information in the book. \n \n ' + parseFloat(this.pickedCrop.n_content_in_residue) + ' kg N/t * ' + parseFloat(this.record.residue) + ' t/ha = ' + parseFloat(this.pickedCrop.n_content_in_residue * this.record.residue) + ' kg N/ha',
      }
      console.log(data);
      this.$store.dispatch("saveNewFieldData", data)
      .then(response => {
        this.$store.commit("SET_FIELD_DATA", ['N', 1, response.data]);
        this.$store.commit("SET_PICKED_FIELD_DATA_POST", this.formatFieldDataRecordPost);
        this.autofillButtonsEnabled = true;
        this.showAutofillResidueSuggestion = false;
        console.log(response.data);
      }).catch(error => {
        this.autofillButtonsEnabled = true;
        console.log(error);
      });
    }


  }
}
</script>
