<template>
  <section>
    <div class="login-register">
      <div class="login-register__header">
        <!--<img src="@/assets/logo.png">-->
        <h1>NutBud</h1>
        <div class="links">
          <h3><router-link :to="{ name: 'login', params: {} }">Login</router-link></h3>
          <h3 class="active">Register</h3>
        </div>
        <p>Register an account to make use of the application</p>
      </div>
      <form action="#" @submit.prevent="register" class="register__form">
        <ul class="dynamic__form">
          <li>
            <label for="name">Name:</label>
            <input type="text" v-model="form.name" id="name"
            :class="{ error: errors.name }"
            @blur="[$store.commit('REMOVE_ERROR', 'name'), $forceUpdate()]">
            <div class="validation__error" v-if="errors.name">{{ errors.name[0] }}</div>
          </li>
          <li>
            <label for="email">Email:</label>
            <input type="email" v-model="form.email" id="email"
            :class="{ error: errors.email }"
            @blur="[$store.commit('REMOVE_ERROR', 'email'), $forceUpdate()]">
            <div class="validation__error" v-if="errors.email">{{ errors.email[0] }}</div>
          </li>
          <li>
            <label for="password">Password:</label>
            <input type="password" v-model="form.password" id="password"
            :class="{ error: errors.password }"
            @blur="[$store.commit('REMOVE_ERROR', 'password'), $forceUpdate()]">
            <div class="validation__error" v-if="errors.password">{{ errors.password[0] }}</div>
          </li>
          <li>
            <button type="button" @click="$store.commit('SET_SHOW_DISCLAIMER_AND_ACKNOWLEDGEMENTS', true)">Disclaimer and Acknowledgements</button>
          </li>
          <li>
            <ul>
              <li class="checkbox" :class="{ checked : acceptedDisclaimer }">
                <label for="disclaimer">
                <input id="disclaimer" type="checkbox" @click="acceptedDisclaimer = !acceptedDisclaimer">
                  I have read and agreed to the DISCLAIMER and Acknowledgements
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="option__group">
          <li><button type="submit" :disabled="!acceptedDisclaimer">Register</button></li>
        </ul>
      </form>
    </div>

    <transition name="right-drawer">
      <Disclaimer v-if="disclaimerAndAcknowledgements"></Disclaimer>
    </transition>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Disclaimer from './Disclaimer';
export default {
  name: "Register",
  components: {
    Disclaimer,
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: ''
      },
      acceptedDisclaimer: false,
      buttonsEnabled: true,
    };
  },
  computed: {
    ...mapGetters(['errors', 'disclaimerAndAcknowledgements']),
  },
  methods: {
    register() {
      this.buttonsEnabled = false;
      this.$store
        .dispatch("register", {
            name: this.form.name,
            email: this.form.email,
            password: this.form.password
        })
        .then(() => {
          // automatic login is handeled in the '.then()' in the store
          //this.$router.push({ name: "login" });
        })
        .catch(error => {
          this.buttonsEnabled = true;
        });

      //TODO: catch the errors when filling in password which is too short, all those rules.
    }
  }
};
</script>
