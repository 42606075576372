import axios from "axios/index";

export default {
  state: {
    field_data: [],
    field_data_record: [],

    picked_field_data: [],
    picked_field_data_post: [],
  },
  getters: {
    field_data: state => state.field_data,
    field_data_record: state => state.field_data_record,
    picked_field_data: state => state.picked_field_data,
    picked_field_data_post: state => state.picked_field_data_post,
    getLatestFieldData(state) {
      // Strips all the field_data but leaves the most recent ones.
      let copy = JSON.parse(JSON.stringify(state.field_data));
      for(let nutrientData in copy) {
        for(let e in copy[nutrientData]) {
          let parkingVariable = copy[nutrientData][e][0];
          if(parkingVariable == undefined) {
            copy[nutrientData][e] = {};
          } else {
            copy[nutrientData][e] = parkingVariable;
          }
        }
      }
      return copy;
    },
    formatFieldDataRecordPre(state, getters, rootState, rootGetters) {
      // Formats the pre-planting-field_data which is attached to the
      // record in the database (field_data_record) to
      // the same structure as the field_data and picked_field_data
      let data = {};
      for(let nutrient in rootGetters['nfrecord/tracked_nutrients']) {
        let filtered = rootGetters['nfrecord/record_data_pre'].filter(
          e => e.nutrient == rootGetters['nfrecord/tracked_nutrients'][nutrient].symbol
          && e.pivot.pre_or_post == 0
        );
        if(filtered[0].soil_or_residue == 0 && !filtered[1]) {
          filtered.push({});
        } else {
          filtered.sort(function (a, b){return a.soil_or_residue - b.soil_or_residue});
        }
        data[rootGetters['nfrecord/tracked_nutrients'][nutrient].symbol] = filtered;
      }
      return data;
    },
    formatFieldDataRecordPost(state, getters, rootState, rootGetters) {
      // Formats the post_harvest-field_data which is attached to the
      // record in the database (field_data_record) to
      // the same structure as the field_data and picked_field_data
      let data = {};
      for(let nutrient in rootGetters['nfrecord/tracked_nutrients']) {
        let filtered = rootGetters['nfrecord/record_data_pre'].filter(
          e => e.nutrient == rootGetters['nfrecord/tracked_nutrients'][nutrient].symbol
          && e.pivot.pre_or_post == 1
        );
        if(filtered[0].soil_or_residue == 0 && !filtered[1]) {
          filtered.push({});
        } else {
          filtered.sort(function (a, b){return a.soil_or_residue - b.soil_or_residue});
        }
        data[rootGetters['nfrecord/tracked_nutrients'][nutrient].symbol] = filtered;
      }
      return data;
    },
  },
  mutations: {
    RETRIEVE_FIELD_DATA(state, field_data) {
      state.field_data = field_data;
    },
    SET_PICKED_FIELD_DATA(state, data) {
      state.picked_field_data = data;
    },
    SET_PICKED_FIELD_DATA_POST(state, data) {
      state.picked_field_data_post = data;
    },
    EDIT_PICKED_FIELD_DATA(state, data) {
      state.picked_field_data[data[0]][data[1]] = data[2];
    },
    EDIT_PICKED_FIELD_DATA_POST(state, data) {
      state.picked_field_data_post[data[0]][data[1]] = data[2];
    },
    RESET_FIELD_DATA(state) {
      state.field_data = {};
    },
    SET_FIELD_DATA(state, data) {
      state.field_data[data[0]][data[1]] = state.field_data[data[0]][data[1]].concat(data[2]);
      state.field_data[data[0]][data[1]].sort(function(a, b) {
        var dateA = new Date(a.date), dateB = new Date(b.date);
        return dateA - dateB;
      });
    },
    EDIT_FIELD_DATA(state, data) {
      state.field_data[data[0]][data[1]] = data[2];
    },
  },
  actions: {
    retrieveFieldData({ getters, commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/field-data/" + data.fieldId)
          .then(response => {
            commit(
              "RETRIEVE_FIELD_DATA", JSON.parse(JSON.stringify(response.data))
            );
            if(data.unsetRecordData) {
              commit("nfrecord/SET_RECORD_DATA_POST", []);
              commit("nfrecord/SET_RECORD_DATA_PRE", []);
            }

            if(data.setPickedFieldDataToRecordData) {
              commit("SET_PICKED_FIELD_DATA", getters.formatFieldDataRecordPre);
            } else {
              commit("SET_PICKED_FIELD_DATA", getters.getLatestFieldData);
            }

            if(data.setPickedFieldDataToRecordDataPost) {
              commit("SET_PICKED_FIELD_DATA_POST", getters.formatFieldDataRecordPost);
            } else {

              // Strips all the field_data but leaves the most recent ones.
              let copy = JSON.parse(JSON.stringify(getters.field_data));
              for(let nutrientData in copy) {
                for(let e in copy[nutrientData]) {
                  copy[nutrientData][e] = {};
                }
              }

              commit("SET_PICKED_FIELD_DATA_POST", copy);
            }

            resolve(JSON.parse(JSON.stringify(response)));
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reset_field_data({ commit }) {
      commit("RESET_FIELD_DATA");
    },
    // TODO Remove rootState thingey
    saveEditedFieldData({ rootState }, data) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .post("field-data/" + data.id, data)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
    saveNewFieldData({rootState}, data) {
      console.log(data);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .post("field-data", data)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
  }
}
