<template>
  <div class="root">
    <p>
      <router-link :to="{ name: 'record', params: {id: record.id} }">
        <img class="icon" src="@/assets/img/record-icon.svg" alt="">
        Record {{ record.id }}:
        <span v-if="fields.find(e => e.id == record.field_id)">{{ fields.find(e => e.id == record.field_id).name }}</span>
        <span v-else><i>field</i></span>
         -
        <span v-if="crops.find(e => e.id == record.crop_id)">{{ crops.find(e => e.id == record.crop_id).name }}</span>
        <span v-else><i>crop</i></span>
      </router-link>
    </p>
    <!-- <button class="markHarvested" @click="markHarvested">
      MARK HARVESTED
    </button> -->
    <div>{{ harvestDate }}</div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "active-record",
  props: {
    record: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  created() {
    },
  data() {
    return {
      id: this.record.id,
      crop: this.record.crop,
      field: this.record.field,
      plantingDate: this.record.plantingDate,
      harvestDate: this.record.harvestDate
    };
  },
  computed: {
    ...mapGetters(['fields', 'crops']),
  },
  methods: {
    markHarvested() {
      // Update local values
      this.harvestDate = "yeah mate, just harvested it";

      // Update values in SSOT using local object,
      // .dispatch() is going to call for a function in the store,
      // which will update the local store (using ".commit()") and the database as well
      this.$store.dispatch("markHarvested", {
        id: this.id,
        crop: this.crop,
        field: this.field,
        plantingDate: this.plantingDate,
        harvestDate: this.harvestDate
      });
      // TODO the local state is changed before the 'database' state is changed. See 1s delay in store.js
      // todo this means that the local state change needs to be swapped?
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  margin: 10px;
  a {
    margin: 10px;
    display: flex;
    align-items: center;
    // img {
    //   margin-right: 10px;
    //   height: 1rem;
    // }
  }
}
</style>
