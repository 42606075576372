import axios from "axios/index";
import Vue from 'vue';

export default {
  state: {
    allCrops: [],
  },
  getters: {
    crops: state => state.allCrops.filter(e => e.new_id == null && e.deleted_at == null),
    archivedCrops: state => state.allCrops.filter(e => e.new_id == null && e.deleted_at != null),
    outDatedCrops: state => state.allCrops.filter(e => e.new_id != null),
    cropsIncludingOutdated: state => state.allCrops.filter(e => e.new_id != null || (e.new_id == null && e.deleted_at == null)),
    cropsWithPickedCrop(state, getters, rootState) {
      return state.allCrops.filter(e => (e.new_id == null && e.deleted_at == null) || e.id == rootState['nfrecord'].record.crop_id)
    }
  },
  mutations: {
    SET_CROPS(state, allCrops) {
      state.allCrops = allCrops;
    },
    ADD_CROPS(state, crops) {
      state.allCrops = state.allCrops.concat(crops);
    },
    UPDATE_CROP(state, data) {
      // Expects id of old version of field,
      // and a field object of the new version of the field

      // Put field into 'outDatedCrops' getter
      Vue.set(state.allCrops[state.allCrops.findIndex(e => e.id == data.oldId)], 'deleted_at', 1);
      Vue.set(state.allCrops[state.allCrops.findIndex(e => e.id == data.oldId)], 'new_id', data.new.id);

      // Add the new field
      state.allCrops = state.allCrops.concat([data.new]);
    },
    ARCHIVE_CROP(state, id) {
      Vue.set(state.allCrops[state.allCrops.findIndex(e => e.id == id)], 'deleted_at', 1);
    },
    RESTORE_CROP(state, id) {
      Vue.set(state.allCrops[state.allCrops.findIndex(e => e.id == id)], 'deleted_at', null);
    },
    // SET_CROPS(state, crops) {
    //   state.crops = crops;
    // },
    // ADD_ARCHIVED_CROPS (state, crops) {
    //   state.archivedCrops = state.archivedCrops.concat(crops);
    // },
    // DEPRECATE_CROP(state, data) {
    //   // Set deprecated crop property 'new_id' to the newly added crop.
    //   state.crops[state.crops.findIndex(e => e.id == data.oldId)].new_id = data.newId;
    // },
    // REPLACE_CROP(state, data) {
    //   state.crops[state.crops.findIndex(e => e.id == data.oldId)] = data.new;
    // },
    // ARCHIVE_CROP(state, crop) {
    //   const index = state.crops.findIndex(item => item.id === crop.id);
    //   state.crops.splice(index, 1);
    //   // state.crops.splice(state.crops.findIndex(e => e.id == crop.id));
    //   state.archivedCrops.concat([crop]);
    // }
  },
  actions: {
    // retrieveCrops({ commit }) {
    //   console.log('retrieving');
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/crops").then(response => {
    //         commit("ADD_CROPS", response.data);
    //         resolve(response);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    storeNewCrop({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/crops", {
            data
          }).then(response => {
            console.log(response.data);
            commit("ADD_CROPS", [response.data]);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeEditCrop({ commit }, crop) {
      console.log('sending', crop);
      return new Promise((resolve, reject) => {
        axios
          .patch("/crops/" + crop.id, crop)
          .then(response => {
            console.log(response.data);
            let data = {
              oldId: crop.id,
              new: response.data,
            };
            commit("UPDATE_CROP", data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    archiveCrop({ commit }, id) {
      axios
        .delete("/crops/" + id)
        .then(() => {
          commit("ARCHIVE_CROP", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    restoreCrop({ commit }, id) {
      axios
        .patch("/crops/" + id + "/restore")
        .then(() => {
          commit("RESTORE_CROP", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
  }
}
