<template lang="html">
  <section class="container step">

    <!-- TODO feedback to user when form needs to be filled in. -->


    <ul class="information__table">
      <li>
        <small>Grower name:</small>
        <p><b>{{ user.name }}</b></p>
      </li>
      <li>
        <small>Trading name:</small>
        <p><b>{{ company.name }}</b></p>
      </li>
      <li>
        <small>Record created on:</small>
        <p><b>{{ record.created_at }}</b></p>
      </li>
    </ul>

    <h3>Tracking</h3>
    <p>Please pick the nutrients you would like to track for this crop cycle:</p>


    <ul>
      <li v-for="nutrient in nutrients" class="checkbox" :class="{ checked : trackedNutrients.find(e => e.id == nutrient.id) }">
        <label :for="nutrient.id">
        <input type="checkbox" @click="alertSetNutrient = false" disabled='disabled' v-model="trackedNutrients" :value="nutrient" :id="nutrient.id">
          {{ nutrient.symbol}} - {{ nutrient.name }}
        </label>
      </li>
    </ul>

    <div v-if="this.alertSetNutrient" class="notification red">
      Please select a nutrient to track!
    </div>

    <nav class="record">
      <SubmitButton
        :status="this.stepOneSubmitButtonStatus"
        text="Submit"
        class="next__step" @submit="trackNutrients">

      </SubmitButton>
    </nav>

  </section>
</template>

<script>
import {mapGetters} from "vuex";

import SubmitButton from "../ui/SubmitButton";

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      trackedNutrients: [],
      alertSetNutrient: false,
      stepOneSubmitButtonStatus: 'idle',
    }
  },
  computed: {
    ...mapGetters("nfrecord", [
      'record',
      'company',
      'user',
      'farm',
      'user',
      'tracked_nutrients',
    ]),

    ...mapGetters([
      'nutrients',
    ]),
  },
  created() {
    this.step1();
  },
  methods: {
    step1 () {
      let tracked = [];
      this.nutrients.forEach((e) => {
        // TODO Once reintroducing other nutrients, uncomment line below, and enable checkbox
        // if(this.record.tracked_nutrients.find(tn => tn.symbol == e.symbol))
        tracked.push(e);
      });
      this.trackedNutrients = tracked;
    },
    trackNutrients() {

      if(this.trackedNutrients.length == 0) {
        this.alertSetNutrient = true;
        return;
      }

      // Create two arrays to compare their contents,
      //  - One with the state on the server
      //  - One with the updated form state
      // after that, decide what to do based on the result.
      var ar1 = [];
      var ar2 = [];

      this.nutrients.forEach(item => {
        ar1.push(this.tracked_nutrients.findIndex(e => e.symbol === item.symbol));
        ar2.push(this.trackedNutrients.findIndex(e => e.symbol === item.symbol));
      });

      if(JSON.stringify(ar1)==JSON.stringify(ar2)) {
        this.$parent.step = 2;
        // Lists are the same, so nothing has to
        // be updated at the backend.
        return;
      }

      let data = {
        nutrients: this.trackedNutrients.map(e => e.id),
        recordId: this.record.id,
      }

      this.stepOneSubmitButtonStatus = 'loading';
      this.$store.dispatch("nfrecord/trackNutrients", data)
      .then(response => {
        this.$parent.step++;
        this.stepOneSubmitButtonStatus = 'success';
      }).catch(error => {
        console.log(error);
        this.stepOneSubmitButtonStatus = 'failure';
      });

      this.reactiveDependency = !this.reactiveDependency;
    },

  }
}
</script>

<style lang="scss" scoped>
.information__table {
  margin-top: 60px;
}
</style>
