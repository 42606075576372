import axios from "axios";
import Vue from 'vue';

export default {
  state: {
    allFields: [],
  },

  getters: {
    fields: state => state.allFields.filter(e => e.new_id == null && e.deleted_at == null),
    archivedFields: state => state.allFields.filter(e => e.new_id == null && e.deleted_at != null),
    outDatedFields: state => state.allFields.filter(e => e.new_id != null),
    fieldsIncludingOutdated: state => state.allFields.filter(e => e.new_id != null || (e.new_id == null && e.deleted_at == null)),
    fieldsWithPickedField(state, getters, rootState) {
      return state.allFields.filter(e => (e.new_id == null && e.deleted_at == null) || e.id == rootState['nfrecord'].record.field_id)
    },
  },

  mutations: {
    SET_FIELDS(state, allFields) {
      state.allFields = allFields;
    },
    ADD_FIELDS(state, fields) {
      state.allFields = state.allFields.concat(fields);
    },
    UPDATE_FIELD(state, data) {
      // Expects id of old version of field,
      // and a field object of the new version of the field

      // Put field into 'outDatedFields' getter
      Vue.set(state.allFields[state.allFields.findIndex(e => e.id == data.oldId)], 'deleted_at', 1);
      Vue.set(state.allFields[state.allFields.findIndex(e => e.id == data.oldId)], 'new_id', data.new.id);

      // Add the new field
      state.allFields = state.allFields.concat([data.new]);
    },
    ARCHIVE_FIELD(state, id) {
      Vue.set(state.allFields[state.allFields.findIndex(e => e.id == id)], 'deleted_at', 1);
    },
    RESTORE_FIELD(state, id) {
      Vue.set(state.allFields[state.allFields.findIndex(e => e.id == id)], 'deleted_at', null);
    },
  },

  actions: {
    storeNewField({ commit }, field) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fields", {
            name: field.name,
            area: field.area,
            soil_type_id: field.soil_type_id,
            farm_id: field.farm_id
          })
          .then(response => {
            commit("ADD_FIELDS", [response.data]);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeEditField({ commit }, field) {
      // Show loading bar
      return new Promise((resolve, reject) => {
        axios
          .patch("/fields/" + field.id, {
            name: field.name,
            area: field.area,
            soil_type_id: field.soil_type_id,
            farm_id: field.farm_id
          })
          .then(response => {
            let data = {
              oldId: field.id,
              new: response.data[0],
            };
            commit('UPDATE_FIELD', data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteField({ commit }, id) {
      axios
        .delete("/fields/" + id)
        .then(() => {
          commit("ARCHIVE_FIELD", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    restoreField({ commit }, id) {
      axios
        .patch("/fields/" + id + "/restore")
        .then(() => {
          commit("RESTORE_FIELD", id);
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
  }
};
