<template lang="html">
  <div class="template__root">

    <StepsNav :title="this.steps[this.step]"></StepsNav>


    <StepConfigure v-if="this.step==1"></StepConfigure>

    <StepField v-if="this.step==2"></StepField>

    <StepCrop v-if="this.step==3"></StepCrop>

    <StepFertilizerPlanning v-if="this.step==4"></StepFertilizerPlanning>

    <CropPlanningOverview v-if="this.step==5"></CropPlanningOverview>

    <StepFertilizerApplied v-if="this.step==6"></StepFertilizerApplied>

    <StepPostHarvestData v-if="this.step==7"></StepPostHarvestData>

    <StepOverView v-if="this.step==8"></StepOverView>


    <transition name="right-drawer">
      <NewFertilizerApplicationExecutionModal
        v-if="showNewFertilizerApplicationExecutionModal">
      </NewFertilizerApplicationExecutionModal>
    </transition>

    <transition name="right-drawer">
      <NewFertilizerApplicationPlanForm
        v-if="showNewFertilizerApplicationPlanForm">
      </NewFertilizerApplicationPlanForm>
    </transition>

    <!--
    <transition name="slide-from-bottom">
      <NewFertilizerApplicationPlanModal
        :crop="pickedCrop"
        :fertilizers="fertilizers"
        :addedFertAmounts="addedFertAmounts"
        :soilPlusResidueAmounts="soilPlusResidueAmounts"
        v-if="showNewFertilizerApplicationPlanModal"
        v-on:newFertilizerApplicationPlanCreated="newFertilizerApplicationPlanCreated($event)">
      </NewFertilizerApplicationPlanModal>
    </transition> -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HexNutrient from "../ui/HexNutrient";
import SubmitButton from "../ui/SubmitButton";

import StepsNav from "./StepsNav";
import NutrientData from "./NutrientData";
import NutrientProgressBar from "./NutrientProgressBar";
import StepConfigure from "./StepConfigure";
import StepField from "./StepField";
import StepCrop from "./StepCrop";
import StepFertilizerPlanning from "./StepFertilizerPlanning";
import CropPlanningOverview from "./CropPlanningOverview";

import StepFertilizerApplied from "./StepFertilizerApplied";
import StepPostHarvestData from "./StepPostHarvestData";
import StepOverView from "./StepOverView";

// import RecordToPdf from '../export/RecordToPdf';

import NewFertilizerApplicationPlanForm from './NewFertilizerApplicationPlanForm';
import NewFertilizerApplicationExecutionModal from './NewFertilizerApplicationExecutionModal';

import FormatDate from "../../mixins/formatDate";

export default {
  name: "record",
  components: {
    StepsNav,
    SubmitButton,
    NutrientData,
    NutrientProgressBar,
    HexNutrient,
    StepConfigure,
    StepField,
    StepCrop,
    StepFertilizerPlanning,
    CropPlanningOverview,
    StepFertilizerApplied,
    StepPostHarvestData,
    // RecordToPdf,
    StepOverView,
    NewFertilizerApplicationPlanForm,
    NewFertilizerApplicationExecutionModal,
  },
  mixins: [FormatDate],
  data() {
    return {
      steps: [
        "",
        "Configure",
        "Field",
        "Crop",
        "Fertilizer Plan",
        "Crop planning",
        "Applied fertilizer",
        "Harvest",
        "Post harvest assessment",
      ],
      step: 0,
    };
  },
  computed: {
    ...mapGetters("nfrecord",[
      "record",
      "tracked_nutrients",
      "record_data_pre",
      "record_data_post",
    ]),
    ...mapGetters([
      'showNewFertilizerApplicationExecutionModal',
      'showNewFertilizerApplicationPlanForm',
    ]),
  },
  created() {
    this.$store.dispatch("retrieveAccountInformation");
    this.$store.dispatch("nfrecord/retrieveRecord", this.$route.params.id)
    .then(response => {
      this.$store.commit("SET_PICKED_FIELD_DATA", {});

      var goToStep = null;

      if(this.tracked_nutrients.length == 0) {
        goToStep = 1;
      }
      if(this.record.field_id) {

        // Check if record has record_data_pre
        let hasRecordDataPre;
        if(this.record_data_pre.length > 0) {
          hasRecordDataPre = true;
        } else {
          hasRecordDataPre = false;
        }

        // Check if record has record_data_post
        let hasRecordDataPost;
        if(this.record_data_post.length > 0) {
          hasRecordDataPost = true;
        } else {
          hasRecordDataPost = false;
        }
        this.$store.dispatch("retrieveFieldData", {
          setPickedFieldDataToRecordData: hasRecordDataPre,
          setPickedFieldDataToRecordDataPost: hasRecordDataPost,
          unsetRecordData: false,
          fieldId: this.record.field_id
        }).then(response => {
          if(!hasRecordDataPre) {
            goToStep = 2;
          } else {
            if(!this.record.crop_id) {
              goToStep = 3;
            } else {
              if(!this.record.planning_confirmed) {
                goToStep = 4;
              } else {
                if(!this.record.fertilizers_applied) {
                  goToStep = 6;
                } else {
                  if(!hasRecordDataPost) {
                    goToStep = 7;
                  } else {
                    goToStep = 8;
                  }
                }
              }
            }
          }
          // this.step = goToStep;
          this.step = 2;

        }).catch(error => {
          console.log(error);
        });
      } else {
        if(goToStep == null) {
          goToStep = 2;
        }
        this.step = goToStep;
      }

    }).catch(error => {
      console.log(error);
      this.$router.push({ name: 'records' });
    });

  },
}
</script>
