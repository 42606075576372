<template lang="html">
  <section class="step step7">
    <h3>General</h3>
    <!-- <button type="button" name="button" @click="$store.commit('SET_DICTIONARY_CONTENT', 'test')">What is this?</button> -->
    <ul class="information__table">
      <li>
        <small>Grower name:</small>
        <p><b>{{ user.name }}</b></p>
      </li>
      <li>
        <small>Trading name:</small>
        <p><b>{{ company.name }}</b></p>
      </li>
      <li>
        <small>Record created on:</small>
        <p><b>{{ formatDate(record.created_at) }}</b></p>
      </li>
    </ul>

    <h3>Field</h3>
    <ul class="information__table">
      <li>
        <small>Area:</small>
        <p style="white-space: pre;"><b>{{ parseFloat(pickedField.area) }}</b>&nbsp;ha</p>
      </li>
      <li>
        <small>Soil type:</small>
        <p><b>{{ pickedField.soil_type.name }} </b></p>
      </li>
    </ul>

    <h3>Tests</h3>
    <!-- <ul class="information__table">
      <li v-for="nutrient in tracked_nutrients">
        <small>{{nutrient.symbol}}</small>
      </li>
    </ul> -->
    <table v-for="nutrient in comparisonTableData" class="comparing__table__overview">
      <tr v-for="data in nutrient">
        <td v-for="text in data">{{ text }}</td>
      </tr>
    </table>

    <h3>Input/Output</h3>
    <table class="comparing__table__overview">
      <tr>
        <td>Exported at harvest</td>
        <td>{{ nitrogenExported }} Kg/ha</td>
      </tr>
      <tr>
        <td>Added using fertilizer</td>
        <td>{{ appliedFertilizerExecutionsAmounts.N }} Kg/ha</td>
      </tr>
      <tr>
        <td>Subtotal:</td>
        <td>{{ parseFloat(appliedFertilizerExecutionsAmounts.N - nitrogenExported).toFixed(2) }} Kg/ha</td>
      </tr>
    </table>
    <!-- <table v-for="nutrient in tracked_nutrients" :key="nutrient.symbol" class="comparing__table__overview">

      <tr>
        <td><b>{{ nutrient.symbol }}</b></td>
        <td>Pre</td>
        <td>Post</td>
        <td>Change</td>
      </tr>

      <tr v-for="sr in sor" :key="+Boolean(!sr)">
        <td>{{ Object.keys(sor)[+Boolean(!sr)] }}</td>
        <td v-if="record_data_pre.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr)">
          {{ parseFloat(record_data_pre.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr).amount) }}
        </td>
        <td v-else>-</td>
        <td v-if="record_data_post.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr)">
          {{ parseFloat(record_data_post.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr).amount) }}
        </td>
        <td v-else>-</td>
        <td v-if="
          record_data_pre.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr)
          && record_data_post.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr)
        ">
          {{
            (record_data_post.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr).amount
            - record_data_pre.find(e => e.nutrient == nutrient.symbol && e.soil_or_residue == !sr).amount)
          }}
        </td>
        <td v-else>?</td>
      </tr>

      <tr>
        <td>Subtotal</td>
        <td v-if="soilPlusResidueAmounts[nutrient.symbol]">
          {{ parseFloat(soilPlusResidueAmounts[nutrient.symbol]) }}
        </td>
        <td v-else>-</td>
        <td v-if="soilPlusResidueAmountsPost[nutrient.symbol]">
          {{ parseFloat(soilPlusResidueAmountsPost[nutrient.symbol]) }}
        </td>
        <td v-else>-</td>
        <td v-if="soilPlusResidueAmounts[nutrient.symbol] && soilPlusResidueAmountsPost[nutrient.symbol]">
          {{ (soilPlusResidueAmountsPost[nutrient.symbol] - soilPlusResidueAmounts[nutrient.symbol]) }}
        </td>
        <td v-else>?</td>
      </tr>
    </table> -->
    <!-- <div class="reddot">
    </div> -->


    <!-- <div style="visibility: hidden; overflow: hidden; height: 1px;"> -->
      <PostToPdfContent id="pdf-content"></PostToPdfContent>
    <!-- </div> -->

    <nav class="record">
      <ul class="option__group">
        <router-link :to="{ name: 'records', params: {} }"></router-link>
        <li>
          <button type="submit" @click="exportToPdf()">
            Export post harvest assessment to PDF
          </button>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import FormatDate from '../../mixins/formatDate';

import PostToPdfContent from '../export/PostToPdfContent';
import {jsPDF} from 'jspdf';

import '../../assets/jsPdfFonts/OpenSans-Regular-normal.js';
import '../../assets/jsPdfFonts/OpenSans-Bold-normal.js';
import '../../assets/jsPdfFonts/Ubuntu-Regular-normal.js';
import '../../assets/jsPdfFonts/Ubuntu-Bold-normal.js';

export default {
  mixins: [FormatDate],
  components: {
    PostToPdfContent,
  },
  computed: {
    ...mapGetters([
      'nutrients',
      'fertilizers',
      'fertilizer_application_plans',
      'fertilizer_application_executions',
      'soil_types',
      'test_types',
      'sor',
    ]),
    ...mapGetters('nfrecord', [
      'record',
      'farm',
      'company',
      'user',
      'tracked_nutrients',
      'record_data_pre',
      'record_data_post',
      'pickedField',
      'pickedCrop',
      'soilPlusResidueAmounts',
      'soilPlusResidueAmountsPost',
      'addedFertilizerPlansAmounts',
      'appliedFertilizerExecutionsAmounts',
      'nitrogenExported',
    ]),
    comparisonTableData() {
      let allNutrientsData = [];
      for(let nutrient in this.tracked_nutrients) {
        let data = [];
        let nutrient_data = [];
        nutrient_data.push(this.tracked_nutrients[nutrient].symbol);
        nutrient_data.push("Pre");
        nutrient_data.push("Post");
        nutrient_data.push("Change");
        data.push(nutrient_data);

        for(let sr in this.sor) {
          nutrient_data = [];
          nutrient_data.push(Object.keys(this.sor)[+Boolean(!this.sor[sr])]);

          let pre_data = '-';
          if(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])) {
            pre_data = parseFloat(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount);
          }
          nutrient_data.push(pre_data);

          let post_data = '-';
          if(this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])) {
            post_data = parseFloat(this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount);
          }
          nutrient_data.push(post_data);

          let compare_data = '-';
          if(this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr])
          && this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]))
          {
            compare_data = parseFloat((this.record_data_post.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount
            - this.record_data_pre.find(e => e.nutrient == this.tracked_nutrients[nutrient].symbol && e.soil_or_residue == +!this.sor[sr]).amount));
            if(compare_data>0) {
              compare_data = '+'+compare_data;
            }
          }
          nutrient_data.push(compare_data);
          data.push(nutrient_data);
        }
        nutrient_data = [];
        nutrient_data.push("Subtotal");
        nutrient_data.push(this.soilPlusResidueAmounts[this.tracked_nutrients[nutrient].symbol]);
        nutrient_data.push(this.soilPlusResidueAmountsPost[this.tracked_nutrients[nutrient].symbol]);
        let addedTotalAmounts = this.soilPlusResidueAmountsPost[this.tracked_nutrients[nutrient].symbol] - this.soilPlusResidueAmounts[this.tracked_nutrients[nutrient].symbol];
        if(addedTotalAmounts > 0) {
          addedTotalAmounts = "+"+addedTotalAmounts;
        }
        nutrient_data.push(addedTotalAmounts);
        data.push(nutrient_data);
        allNutrientsData.push(data);
      }
      return allNutrientsData;
    },
  },
  methods: {
    exportToPdf() {
      console.log('generating pdf');
      var doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',

      });
      console.log(doc.getFontList());
      doc.setFont('OpenSans-Regular');
      doc.setFont('OpenSans-Bold');
      doc.setFont('Ubuntu-Bold');
      doc.html(document.getElementById('pdf-content'), {
        callback: function (doc) {
          doc.save();
        },
        x: 0,
        y: 0,
      });
    }
  }
}
</script>
