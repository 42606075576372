<template lang="html">
  <section class="container step">

    <h3>Field selection</h3>

    <ul class="dynamic__form">
      <li class="select__and__preferences">
        <select
          v-model="selectedFieldId"
          v-on:change="pickField"
          v-on:blur="toggleSelect(false)"
          @focus="toggleSelect(true)"
          :disabled="(this.loadingFieldSelect || this.fieldSelectDisabled)">
          <!-- <option value="" hidden>Please select a field</option> -->
          <!-- <option v-if="outdated_data.field" :value="outdated_data.field.id">{{ outdated_data.field.name }}</option> -->
          <option :value="field.id" :key="field.id" v-for="field in fieldsWithPickedField">{{field.name}} <span v-if="field.new_id">[outdated]</span> </option>
          <!-- <option :value="pickedField.id" :key="pickedField.id" v-if="fields.findIndex(e => e.id == pickedField.id) == -1">
            {{pickedField.name}}
            <span v-if="pickedField.new_id">
              [outdated]
            </span>
          </option> -->
        </select>
        <img src="@/assets/img/dropdown-arrow.svg" :class="{ opened: selectFocussed }" class="arrow" alt="">
        <button type="button" @click="$store.commit('SET_SHOW_FIELD_PREFERENCES', true)">
          <img src="@/assets/img/gear.svg" alt="">
        </button>
      </li>
    </ul>

    <div v-if="pickedField">
      <div class="inline__notification" @click="toggleDeprecatedFieldNotification()" v-if="pickedField.new_id">
        <h4>Deprecated field</h4>
        <div class="expanded" v-if="deprecatedFieldNotification">
          <p><small><i>
            The chosen field has been updated. Would you like to update the field to the new data?
          </i></small></p>
          <ul class="option__group">
            <li><button type="button" @click="toggleDeprecatedFieldNotification()">Dismiss</button></li>
            <li><button type="submit" @click="[selectedFieldId = pickedField.new_id, pickField(), toggleDeprecatedFieldNotification()]">Update</button></li>
          </ul>
        </div>
      </div>
    </div>

    <ul class="information__table" v-if="pickedField && !loadingFieldSelect && !this.showFieldForm">
      <li>
        <small>Area:</small>
        <p style="white-space: pre;"><b>{{ parseFloat(pickedField.area) }}</b>&nbsp;ha</p>
      </li>
      <li>
        <small>Soil type:</small>
        <p><b>{{ pickedField.soil_type.name }} </b></p>
      </li>
    </ul>

    <p class="notification gray" v-if="!pickedField && !loadingFieldSelect">
      Please select a field in order to continue.
    </p>

    <div class="notification loading" v-if="loadingFieldSelect">
      <div class="spinner button-content">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <h3>Field data <img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'field_data')"></h3>
    <p class="notification gray" v-if="!pickedField">
      Please select a field!
    </p>

    <div class="field__data" v-if="pickedField">
      <div v-if="this.loadingFieldData" class="notification loading">
        <!-- TODO extract spinner into component -->
        <div class="spinner button-content">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>

      <div class="nutrients" v-if="!this.loadingFieldData && !this.loadingFieldSelect">
        <div class="nutrients__helper">
          <NutrientData
            v-for="(nutrient, index) in this.tracked_nutrients"
            :key="nutrient.symbol"
            :nkey="nutrient.symbol"
            :data="picked_field_data"
            :pre_or_post="0"
            :soilPlusResidueAmountsProp="soilPlusResidueAmounts"
            v-on:updated="updateStepTwoSubmitButtonStatus()"
            >
          </NutrientData>
        </div>
      </div>
    </div>

    <nav class="record">
      <SubmitButton
        :status="this.stepTwoSubmitButtonStatus"
        text="Submit"
        @click.native="submitStepTwo()"
        class="next__step">
      </SubmitButton>
    </nav>

    <transition name="right-drawer">
      <FieldPreferences
        v-on:fieldPicked="fieldPicked"
        v-if="showFieldPreferences">
      </FieldPreferences>
    </transition>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import NutrientData from "./NutrientData";
import SubmitButton from "../ui/SubmitButton";

import FieldPreferences from "../preferences/FieldPreferences";

export default {
  components: {
    NutrientData,
    SubmitButton,
    FieldPreferences,
  },
  data() {
    return {
      selectedFieldId: '',
      fieldSelectDisabled: false,
      loadingFieldSelect: false,
      loadingFieldData: false,
      selectFocussed: false,
      showFieldForm: false,
      deprecatedFieldNotification: false,
      form: {},
      formStatus: 'disabled',
      formValid: false,
      stepTwoSubmitButtonStatus: 'disabled',
    };
  },
  computed: {
    ...mapGetters('nfrecord', [
      'record',
      'tracked_nutrients',
      'pickedField',
      'soilPlusResidueAmounts',
    ]),
    ...mapGetters([
      'fieldsWithPickedField',
      'farm',
      'picked_field_data',
      'soil_types',
      'showFieldPreferences',
    ]),
  },
  created() {
    if(this.record.field_id) {
      this.selectedFieldId = this.record.field_id;
    }
    this.updateStepTwoSubmitButtonStatus();
  },
  methods: {
    goToFieldPreferences() {
      this.$store.commit('SET_RETURN_HATCH', 'records/' + this.record.id);
      this.$router.push({ name: 'fields' });
    },
    // showNewFieldForm() {
    //   this.showFieldForm = true;
    //   this.fieldSelectDisabled = true;
    //   this.form = {
    //     'name': '',
    //     'area': '',
    //     'soil_type': ''
    //   };
    //   this.formStatus = 'new';
    // },
    // validate() {
    //   if(!this.form.name | !this.form.area | !this.form.soil_type_id) {
    //     this.formValid = false;
    //   } else {
    //     this.formValid = true;
    //   }
    // },
    // cancelForm() {
    //   this.showFieldForm = false;
    //   this.fieldSelectDisabled = false;
    // },
    // submitEdit() {
    //   console.log(this.form);
    // },
    // submitNew() {
    //   this.formStatus = 'disabled';
    //   this.formValid = false;
    //   this.$store.dispatch("addField", {
    //     name: this.form.name,
    //     area: this.form.area,
    //     soil_type_id: this.form.soil_type_id,
    //     farm_id: this.farm.id
    //   }).then(response => {
    //     console.log(response.data);
    //     this.showFieldForm = false;
    //     this.fieldSelectDisabled = false;
    //     this.selectedFieldId = response.data.id;
    //     // this.$store.commit("SET_RECORD_DATA", {});
    //     // this.$store.commit("SET_FIELD_DATA", {});
    //     this.pickField();
    //   }).catch((error) => {
    //     this.validate();
    //     this.formStatus = 'edit';
    //     // TODO feedback that the field did not get created
    //   });
    // },
    toggleSelect(status) {
      this.selectFocussed = status;
    },
    pickField() {
    console.log('pickField()...');
      // // TODO unset field_data_record when choosing a new field.
      // if(this.selectedFieldId == undefined) {
      //   console.log('s');
      //   return;
      // }
      // if(this.record.field_id == this.selectedFieldId) {
      //   this.$store.commit("SET_RECORD_PICKED_FIELD", this.record.fiel_id);
      //   return;
      // }
      if(this.selectedFieldId == ""){
        return;
      }

      // TODO Disable select
      this.loadingFieldSelect = true;
      this.$store.commit("RESET_FIELD_DATA");

      // TODO feedback loading
      this.$store.dispatch("nfrecord/pickField", {
        recordId: this.record.id,
        fieldId: this.selectedFieldId,
      }).then(response => {
        // if(this.pickedField.new_id == null) {
        //   this.$store.commit("REMOVE_DEPRECATED_FIELDS");
        // }

        this.loadingFieldSelect = false;

        this.loadingFieldData = true;

        // Retrieve the field data.
        this.$store.dispatch("retrieveFieldData", {
          setPickedFieldDataToRecordData: false,
          unsetRecordData: true,
          fieldId: this.record.field_id
        }).then(response => {
          this.updateStepTwoSubmitButtonStatus();
          this.loadingFieldData = false;
        }).catch(error => {
          this.updateStepTwoSubmitButtonStatus();
          this.loadingFieldData = false;
          console.log(error);
        });
      })
      .catch(error => {
        this.loadingFieldSelect = false;
      });
    },
    toggleDeprecatedFieldNotification() {
      this.deprecatedFieldNotification = !this.deprecatedFieldNotification;
    },
    updateStepTwoSubmitButtonStatus() {
      if(Object.keys(this.picked_field_data).length == 0) {
        return;
      }
      let status = 'idle';
      for(var nutrient in this.tracked_nutrients) {
        var e = this.picked_field_data[this.tracked_nutrients[nutrient].symbol];
        if(e[0].amount == undefined) {
          status = 'disabled';
          break;
        }
      }
      this.stepTwoSubmitButtonStatus = status;
    },
    submitStepTwo() {
      // Get id's from all the picked field data.
      let data = [];
      for(let i in this.picked_field_data) {
        let n = this.picked_field_data[i];
        if(n[0].amount) {
          data.push(n[0].id);
          if(n[1].amount) {
            data.push(n[1].id);
          }
        }
      }
      this.stepTwoSubmitButtonStatus = 'loading';
      // Send data
      this.$store.dispatch("nfrecord/saveRecordFieldData", {
        pre_or_post: 0,
        recordData: data,
        recordId: this.record.id
      }).then(response => {
        this.stepTwoSubmitButtonStatus = 'idle';
        this.$parent.step++;
      }).catch(error => {
        this.stepTwoSubmitButtonStatus = 'idle';
        console.log(error);
      });
    },
    fieldPicked(id) {
      console.log(id);
      this.selectedFieldId = id;
    }
  }
}
</script>
