import axios from "axios/index";

export default {
  state: {
    fertilizer_application_plans: [],
    fertilizer_application_executions: [],
  },
  getters: {
    fertilizer_application_plans: state => state.fertilizer_application_plans,
    fertilizer_application_executions: state => state.fertilizer_application_executions,
  },
  mutations: {
    SET_FERTILIZER_APPLICATION_EXECUTIONS (state, f) {
      state.fertilizer_application_executions = f;
    },
    SET_FERTILIZER_APPLICATION_PLANS (state, f) {
      state.fertilizer_application_plans = f;
    },
    PUSH_NEW_FERTILIZER_APPLICATION_PLAN (state, f) {
      state.fertilizer_application_plans.push(f);
    },
    PUSH_NEW_FERTILIZER_APPLICATION_EXECUTION (state, f) {
      state.fertilizer_application_executions.push(f);
    },
    DELETE_FERTILIZER_APPLICATION_PLAN(state, index) {
      state.fertilizer_application_plans.splice(index, 1);
    },
    DELETE_FERTILIZER_APPLICATION_EXECUTION(state, index) {
      state.fertilizer_application_executions.splice(index, 1);
    },
  },
  actions: {
    storeNewFertilizerApplicationPlan({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fert-app-plan", data)
          .then(response => {
            commit("PUSH_NEW_FERTILIZER_APPLICATION_PLAN", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteFertilizerApplicationPlan({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/fert-app-plan/" + data.id)
          .then(response => {
            commit("DELETE_FERTILIZER_APPLICATION_PLAN", data.index);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    storeNewFertilizerApplicationExecution({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fertilizer-application-execution", data)
          .then(response => {
            commit("SET_FERTILIZER_APPLICATION_EXECUTIONS", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteFertilizerApplicationExecution({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/fertilizer-application-execution/" + data.id)
          .then(response => {
            commit("DELETE_FERTILIZER_APPLICATION_EXECUTION", data.index);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    executeAll({ commit }, e) {
      return new Promise((resolve, reject) => {
        axios
        .post("/fertilizer-application-executions", e.data)
        .then(response => {
          commit("SET_FERTILIZER_APPLICATION_EXECUTIONS", response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
  }
}
