<template lang="html">
  <div class="drawer">
    <section>

      <h3 v-if="setting=='new'">New Crop<img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'crop_form')"></h3>
      <h3 v-if="setting=='edit'">Edit Crop<img src="@/assets/img/question.svg" alt="Open explanation" @click="$store.commit('SET_DICTIONARY_CONTENT', 'crop_form')"></h3>

      <ul class="dynamic__form">
        <li>
          <label for="name">Name:</label>
          <input type="text" v-model="form.name" id="name"
          :class="{ error: errors.name }"
          @blur="[$store.commit('REMOVE_ERROR', 'name'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.name">{{ errors.name[0] }}</div>
        </li>

        <li class="medium">
          <label for="standard_marketable_yield">Standard marketable yield:</label>
          <input type="number" v-model="form.standard_marketable_yield" id="standard_marketable_yield"
          :class="{ error: errors.standard_marketable_yield }"
          @blur="[$store.commit('REMOVE_ERROR', 'standard_marketable_yield'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.standard_marketable_yield">{{ errors.standard_marketable_yield[0] }}</div>
          <span class="unit">t/ha</span>
        </li>

        <li class="short">
          <label for="n_content_in_yield">N content in yield:</label>
          <input type="number" v-model="form.n_content_in_yield" id="n_content_in_yield"
          :class="{ error: errors.n_content_in_yield }"
          @blur="[$store.commit('REMOVE_ERROR', 'n_content_in_yield'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.n_content_in_yield">{{ errors.n_content_in_yield[0] }}</div>
          <span class="unit">kg N/t yield</span>
        </li>

        <li class="short">
          <label for="residue">Crop residue:</label>
          <input type="number" v-model="form.residue" id="residue"
          :class="{ error: errors.residue }"
          @blur="[$store.commit('REMOVE_ERROR', 'residue'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.residue">{{ errors.residue[0] }}</div>
          <span class="unit">t/ha</span>
        </li>

        <li class="short">
          <label for="n_content_in_residue">N content in residue:</label>
          <input type="number" v-model="form.n_content_in_residue" id="n_content_in_residue"
          :class="{ error: errors.n_content_in_residue }"
          @blur="[$store.commit('REMOVE_ERROR', 'n_content_in_residue'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.n_content_in_residue">{{ errors.n_content_in_residue[0] }}</div>
          <span class="unit">kg N/t residue</span>
        </li>

        <li class="medium">
          <label for="soil_minimum_n_at_harvest">Soil N minimum at harvest:</label>
          <input type="number" v-model="form.soil_minimum_n_at_harvest" id="soil_minimum_n_at_harvest"
          :class="{ error: errors.soil_minimum_n_at_harvest }"
          @blur="[$store.commit('REMOVE_ERROR', 'soil_minimum_n_at_harvest'), $forceUpdate()]">
          <div class="validation__error" v-if="errors.soil_minimum_n_at_harvest">{{ errors.soil_minimum_n_at_harvest[0] }}</div>
          <span class="unit">kg N/ha</span>
        </li>
      </ul>

      <ul class="option__group" v-if="setting == 'edit'">
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitEditCrop">Edit</button>
      </ul>

      <ul class="option__group" v-else>
        <button type="button" :class="{ loading: !buttonsEnabled }" @click="closeModal">Close</button>
        <button type="submit" :class="{ loading: !buttonsEnabled }" @click="submitNewCrop">Create New</button>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  created(){
    this.$store.commit("RESET_ERRORS");

    // overwrite the form with data if provided
    if(this.data != undefined) {
      this.setting = 'edit';
      this.form = JSON.parse(JSON.stringify(this.data));
      this.form.standard_marketable_yield = parseFloat(this.form.standard_marketable_yield);
      this.form.n_content_in_yield = parseFloat(this.form.n_content_in_yield);
      this.form.residue = parseFloat(this.form.residue);
      this.form.n_content_in_residue = parseFloat(this.form.n_content_in_residue);
      this.form.soil_minimum_n_at_harvest = parseFloat(this.form.soil_minimum_n_at_harvest);
    }
  },
  props: {
    data: '',
  },
  data() {
    return {
      // Standard values of a crop.
      form: {
        company_id: null,

        standard_marketable_yield: null,
        n_content_in_yield: null,
        residue: null,
        n_content_in_residue: null,
        soil_minimum_n_at_harvest: 0,

        offset: null,

        n_soil_saturated_at: null,
        set_n_recommendation: null,
        soil_factor: -1,

        growth_period: null,
      },
      setting: 'new',
      buttonsEnabled: true,
    }
  },
  computed: {
    ...mapGetters(['showCropForm', 'errors', 'company']),
  },
  methods: {
    closeModal() {
      this.$store.commit("SET_SHOW_CROP_FORM", false);
    },
    submitEditCrop() {
      this.buttonsEnabled = false;
      console.log(this.form);
      let data = JSON.parse(JSON.stringify(this.form));
      this.$store.dispatch("storeEditCrop", data)
      .then(response => {
        this.buttonsEnabled = true;
        console.log(response.data);
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_CROP_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        console.log(error);
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    submitNewCrop() {
      let data = JSON.parse(JSON.stringify(this.form));
      // A user is only allowed to create a new crop, or to edit one of their own
      // crops.

      // this.buttonsEnabled = false;
      data.company_id = this.company.id;
      data.offset = (this.form.standard_marketable_yield * this.form.n_content_in_yield) +
      (this.form.residue * this.form.n_content_in_residue) +
      parseFloat(this.form.soil_minimum_n_at_harvest);
      console.log(data);
      this.$store.dispatch("storeNewCrop", data)
      .then(response => {
        console.log(response.data);
        this.buttonsEnabled = true;
        this.$emit("newId", response.data.id);
        this.$store.commit("SET_SHOW_CROP_FORM", false);
      }).catch(error => {
        this.buttonsEnabled = true;
        this.$store.commit("SET_ERRORS", error.response.data.errors);
      });
    },
    removeError(inputCrop) {
      this.$store.commit("REMOVE_ERROR", inputCrop);
      this.$forceUpdate();
    },
  }
}
</script>
