export default {
  methods: {
    formatDate(date) {
      const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(new Date(date) );

      return `${day}-${month}-${year}`;
    },

    readableDateToYYYYMMDD(date) {
      var day = date.slice(0,2);
      var month = date.slice(3, 5);
      var year = date.slice(6);
      return year + "-" + month + "-" + day;
    },

    readableDateToMMDDYYYY(date) {
      var day = date.slice(0,2);
      var month = date.slice(3, 5);
      var year = date.slice(6);
      return "'" + month + "-" + day + "-" + year + "'";
    },
  }
}
