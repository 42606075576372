export default {
  state: {
    errors: []
  },
  getters: {
    errors: state => state.errors
  },
  mutations: {
    RESET_ERRORS(state) {
      state.errors = {};
    },
    SET_ERRORS (state, newErrors) {
      state.errors = newErrors;
    },
    REMOVE_ERROR (state, field) {
      delete state.errors[field];
    }
  },
}
