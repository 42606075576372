<template lang="html">
  <div class="nutrient__data" :class="this.colorCSS">
    <div class="top" @click="toggleFold()">
      <div class="hex__container">
        <div class="hexagon" :class="this.colorCSS">
          {{ nkey }}
        </div>
      </div>
      <ul>
        <li>
          <small>Residue</small>
          <p>{{ !this.data[this.nkey][1].hasOwnProperty('amount') ? '-' : parseFloat(this.data[this.nkey][1].amount) }}</p>
        </li>
        <li>
          <small>Soil</small>
          <p>{{ !this.data[this.nkey][0].hasOwnProperty('amount') ? '-' : parseFloat(this.data[this.nkey][0].amount) }}</p>
        </li>
        <li>
          <small>Total</small>
          <p>{{ isNaN(parseFloat(this.soilPlusResidueAmountsProp[this.nkey])) ? '-' : parseFloat(this.soilPlusResidueAmountsProp[this.nkey]) }}</p>
        </li>
      </ul>
    </div>
    <div class="data" v-if="!this.cardFolded">
      <p class="retract" @click="toggleFold()"><small>Retract</small></p>
      <form v-on:submit.prevent v-for="sr in sor" :key="sr">
        <hr v-if="sr > 0">
        <ul class="option__group space-between">
          <li><h4>{{ Object.keys(sor)[sr] }} data</h4></li>
          <li>
            <button type="button" @click="openHistory(sr)" :disabled="field_data[nkey][sr].length == 0" class="icon"><img src="@/assets/img/history.svg" alt="history"></button>
          </li>
        </ul>
        <ul class="dynamic__form">
          <li>
            <label :for="'amount'+sr"><small>Amount</small></label>
            <input :id="'amount'+sr" type="number" @change="validate(sr)" v-model="form[sr].amount" required :disabled="formStatus[sr]=='disabled'">
            <span class="unit">Kg/ha</span>
          </li>
          <li>
            <label :for="'test_type'+sr"><small>Test type</small></label>
            <select :id="'test_type'+sr" @change="validate(sr)" v-model="form[sr].test_type" required :disabled="formStatus[sr]=='disabled'">
              <option v-for="(type, index) in test_types[nkey].filter(function (type) { return type.soil_or_residue == sr; })">{{ type.test_type }}</option>
            </select>
          </li>
          <li>
            <label :for="'date'+sr"><small>Date</small></label>
            <input :id="'date'+sr" type="date" @change="validate(sr)" v-model.date="form[sr].date" required :disabled="formStatus[sr]=='disabled'">
          </li>
          <li>
            <label :for="'additional_info'+sr"><small>Additional information:</small></label>
            <textarea :id="'additional_info'+sr" v-model="form[sr].additional_info" :disabled="formStatus[sr]=='disabled'"></textarea>
          </li>
        </ul>
        <ul class="option__group" v-if="formStatus[sr]=='disabled'">
          <li><button type="button" @click="edit(sr)" v-if="form[sr].amount">Edit</button></li>
          <li><button type="button" @click="clear(sr)">New</button></li>
        </ul>
        <ul class="option__group" v-if="formStatus[sr]=='edit'">
          <li><button type="button" @click="cancelForm(sr)">Cancel</button></li>
          <li><button type="submit" @click="submitEdit(sr)" :disabled="!valid[sr]">Save edit</button></li>
        </ul>
        <ul class="option__group" v-if="formStatus[sr]=='new'">
          <li><button type="button" @click="cancelForm(sr)">Cancel</button></li>
          <li><button type="submit" @click="submitNew(sr)" :disabled="!valid[sr]">Save new</button></li>
        </ul>
        <ul class="option__group" v-if="formStatus[sr]=='loading'">
          <li class="notification loading">
            <div class="spinner button-content">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </li>
        </ul>
      </form>
      <p class="retract" @click="toggleFold()"><small>Retract</small></p>
    </div>
    <transition name="right-drawer">
      <PickFieldData v-if="this.showModal"
        :pre_or_post="this.pre_or_post"
        v-on:select="pickHistory"
      ></PickFieldData>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import PickFieldData from "./PickFieldData";

export default {
  data() {
    return {
      colorCSS: '',
      cardFolded: true,
      form: [{
        'amount': '',
        'test_type': '',
        'date': '',
        'additional_info': ''
      }, {
        'amount': '',
        'test_type': '',
        'date': '',
        'additional_info': ''
      }],
      formStatus: [
        'disabled',
        'disabled',
      ],
      errors: {},
      valid: [false, false],
      reactiveDependency: false,
    }
  },
  components: {
    PickFieldData,
  },
  created() {
    this.checkColor('init');
    this.populateForm();

    for(let e in this.form) {
      if(!this.form[e].amount) {
        this.formStatus[e] = 'new';
      }
    }
  },
  props: {
    nkey: '',
    data: '',
    pre_or_post: '',
    soilPlusResidueAmountsProp: '',
  },
  computed: {
    ...mapGetters('nfrecord', [
      'record',
    ]),
    ...mapGetters([
      'sor',
      'test_types',
      'field_data',
      'picked_field_data',
      'selectFieldDataModal',
    ]),
    showModal: function () {
      if(this.selectFieldDataModal != null) {
        if(this.selectFieldDataModal.nutrient == this.nkey){
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    checkColor(situation) {
      if(this.data[this.nkey] == undefined) {
        this.colorCSS = '';
        return;
      }
      if(this.data[this.nkey][0] == undefined) {
        this.colorCSS = '';
        return;
      }
      if(this.data[this.nkey][0].amount != null) {
        this.colorCSS = 'green';
        return;
      }
      if(this.data[this.nkey][0].amount == null) {
        if(situation == 'init') {
          this.colorCSS = '';
        }else if (situation == 'check') {
          this.colorCSS = 'red';
        }
      }
    },
    toggleFold() {
      this.cardFolded = !this.cardFolded;
    },
    populateForm() {
      if(Object.keys(this.data).length == 0) {
        return;
      }

      this.form = [];
      for(let i = 0;i < 2; i++) {
        if(this.data[this.nkey][i].amount) {
          this.form.push(JSON.parse(JSON.stringify(this.data[this.nkey][i])));
          if(this.form[this.form.length - 1].amount != undefined) {
            this.form[this.form.length - 1].amount = parseFloat(this.form[this.form.length - 1].amount);
          }
        } else {
          this.form.push({
            'amount': '',
            'test_type': '',
            'date': '',
            'additional_info': ''
          });
        }
      }
    },
    edit(sr) {
      this.formStatus[sr] = 'edit';
      this.validate(sr);
      this.$forceUpdate();
    },
    clear(sr) {
      this.form[sr] = {};
      this.formStatus[sr] = 'new';
      this.$forceUpdate();
    },
    validate(sr) {
      if(!this.form[sr].amount | !this.form[sr].test_type | !this.form[sr].date) {
        this.valid[sr] = false;
      } else {
        this.valid[sr] = true;
      }
      this.$forceUpdate();
    },
    cancelForm(sr) {
      this.form[sr] = JSON.parse(JSON.stringify(this.data[this.nkey][sr]));
      this.formStatus[sr] = 'disabled';
      this.$store.commit("nfrecord/TOGGLE_REACTIVE_DEPENDENCY");
      this.$forceUpdate();
    },
    submitEdit(sr) {
      this.valid[sr] = false;
      this.formStatus[sr] = 'loading';
      this.$forceUpdate();
      this.$store.dispatch("saveEditedFieldData", this.form[sr])
      .then(response => {
        this.$store.commit("EDIT_FIELD_DATA", [this.nkey, sr, response.data]);
        if(!this.pre_or_post) {
          this.$store.commit("EDIT_PICKED_FIELD_DATA", [this.nkey, sr, response.data]);
        } else {
          this.$store.commit("EDIT_PICKED_FIELD_DATA_POST", [this.nkey, sr, response.data]);
        }

        let data = [];
        for(let i in this.picked_field_data) {
          let n = this.picked_field_data[i];
          if(n[0].amount) {
            data.push(n[0].id);
            if(n[1].amount) {
              data.push(n[1].id);
            }
          }
        }

        this.stepTwoSubmitButtonStatus = 'loading';
        this.$store.dispatch("nfrecord/saveRecordFieldData", {
          pre_or_post: 0,
          recordData: data,
          recordId: this.record.id
        }).then(response => {
          this.stepTwoSubmitButtonStatus = 'idle';
        }).catch(error => {
          this.stepTwoSubmitButtonStatus = 'idle';
          console.log(error);
        });

        this.form[sr] = JSON.parse(JSON.stringify(response.data));
        this.formStatus[sr] = 'disabled';
        this.$store.commit("nfrecord/TOGGLE_REACTIVE_DEPENDENCY");
        this.reactiveDependency = !this.reactiveDependency;
        this.$forceUpdate();
        this.$emit('updated');
      }).catch(error => {
        console.log(error);
        this.errors[sr] = error.response.data.errors;
        this.formStatus[sr] = 'edit';
        this.$forceUpdate();
      });
      this.checkColor('init');
    },
    submitNew(sr) {
      this.valid[sr] = false;
      let data = {
          field_id: this.record.field_id,
          nutrient: this.nkey,
          amount: this.form[sr].amount,
          date: this.form[sr].date,
          test_type: this.form[sr].test_type,
          soil_or_residue: +sr
      }

      if(this.form[sr].additional_info) {
        data.additional_info = this.form[sr].additional_info;
      }else {
        data.additional_info = '-';
      }

      this.formStatus[sr] = 'loading';
      this.$forceUpdate();
      this.$store.dispatch("saveNewFieldData", data)
      .then(response => {
        this.$store.commit("SET_FIELD_DATA", [this.nkey, sr, response.data]);
        if(!this.pre_or_post) {
          this.$store.commit("EDIT_PICKED_FIELD_DATA", [this.nkey, sr, response.data]);
        } else {
          this.$store.commit("EDIT_PICKED_FIELD_DATA_POST", [this.nkey, sr, response.data]);
        }

        let data = [];
        for(let i in this.picked_field_data) {
          let n = this.picked_field_data[i];
          if(n[0].amount) {
            data.push(n[0].id);
            if(n[1].amount) {
              data.push(n[1].id);
            }
          }
        }

        this.stepTwoSubmitButtonStatus = 'loading';
        this.$store.dispatch("nfrecord/saveRecordFieldData", {
          pre_or_post: 0,
          recordData: data,
          recordId: this.record.id
        }).then(response => {
          this.stepTwoSubmitButtonStatus = 'idle';
        }).catch(error => {
          this.stepTwoSubmitButtonStatus = 'idle';
          console.log(error);
        });

        this.form[sr] = JSON.parse(JSON.stringify(response.data));
        this.formStatus[sr] = 'disabled';
        this.$store.commit("nfrecord/TOGGLE_REACTIVE_DEPENDENCY");
        this.checkColor('init');
        this.reactiveDependency = !this.reactiveDependency;
        this.$forceUpdate();
        this.$emit('updated');
      }).catch(error => {
        console.log(error);
        // this.errors[sr] = error.response.data.errors;
        this.formStatus[sr] = 'new';
        this.$forceUpdate();
      });
    },
    openHistory(sr) {
      this.$store.commit("SET_SHOW_SELECT_FIELD_DATA_MODAL", {nutrient: this.nkey, sor: sr});
    },
    pickHistory(sr, id) {
      let new_field_data = [];
      new_field_data.push(this.nkey);
      new_field_data.push(sr);
      new_field_data.push(this.field_data[this.nkey][sr].find(e => e.id == id));
      if(!this.pre_or_post) {
        this.$store.commit("EDIT_PICKED_FIELD_DATA", [this.nkey, sr, new_field_data[2]]);
      } else {
        this.$store.commit("EDIT_PICKED_FIELD_DATA_POST", [this.nkey, sr, new_field_data[2]]);
      }

      let data = [];
      for(let i in this.picked_field_data) {
        let n = this.picked_field_data[i];
        if(n[0].amount) {
          data.push(n[0].id);
          if(n[1].amount) {
            data.push(n[1].id);
          }
        }
      }

      this.stepTwoSubmitButtonStatus = 'loading';
      this.$store.dispatch("nfrecord/saveRecordFieldData", {
        pre_or_post: 0,
        recordData: data,
        recordId: this.record.id
      }).then(response => {
        this.stepTwoSubmitButtonStatus = 'idle';
      }).catch(error => {
        this.stepTwoSubmitButtonStatus = 'idle';
        console.log(error);
      });

      this.$store.commit("nfrecord/TOGGLE_REACTIVE_DEPENDENCY");
      this.$store.commit("SET_SHOW_SELECT_FIELD_DATA_MODAL", null);
      this.form[sr] = this.data[this.nkey][sr];
      this.reactiveDependency = !this.reactiveDependency;
      this.checkColor('init');
      this.formStatus[sr] = 'disabled';
    },
  }
}
</script>
