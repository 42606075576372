<template lang="html">
  <button
    type="submit"
    class="aSyncButton primary"
    :class="{loading: status === 'loading'}"
    :disabled="status === 'loading' || status === 'disabled'"
    @click="emit()">

    <transition name="slide-from-top-fade">
      <span v-if="status === 'idle' || status === 'disabled'" class="button-content">{{ text }}</span>
    </transition>



    <transition name="slide-from-top-fade">
      <div v-if="status === 'loading'" class="spinner button-content">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </transition>

    <transition name="slide-from-top-fade">
      <span v-if="status === 'success_icon'" class="button-content">V</span>
    </transition>

    <transition name="slide-from-top-fade">
      <span v-if="status === 'failure_icon'" class="button-content">X</span>
    </transition>

    <transition name="slide-from-top-fade">
      <span v-if="status === 'success'" class="button-content">Success</span>
    </transition>

    <transition name="slide-from-top-fade">
      <span v-if="status === 'success-icon'" class="button-content">YEAH</span>
    </transition>

    <transition name="slide-from-top-fade">
      <span v-if="status === 'failure'" class="button-content">Retry</span>
    </transition>

  </button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    text: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
  },
  methods: {
    emit() {
      this.$emit('submit');
    }
  }
}
</script>

<style lang="scss" type="text/css">

.aSyncButton {
  min-width: 80px;
  min-height: 40px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-content {
  position: absolute;
}

.slide-from-top-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-from-top-fade-enter-active {
  transition: all .7s cubic-bezier(0, .5, 0, 1);
}

.slide-from-top-fade-enter-to {
  opacity: 1;
}


.slide-from-top-fade-leave {
  opacity: 1;
}

.slide-from-top-fade-leave-active {
  transition: all .7s cubic-bezier(0, .5, 0, 1);
}

.slide-from-top-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

/* Loader */

</style>
