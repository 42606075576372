<template lang="html">
  <aside class="dictionary">
    <section>
      <div v-if="dictionaryContent == 'nutrient_progress_bar'">
        <ul>
          <li><b>Light gray bar:</b> This is the residue data available.</li>
          <li><b>Green/red bar:</b> This is the amount of nutrients added when applying the chosen fertilizers.</li>
          <li><b>Dark gray bar:</b> This is the target bar.</li>
        </ul>
      </div>

      <div v-if="dictionaryContent == 'field_data'">
        <ul>
          <li>Please note: Upon refreshing the page, the website will automatically
          look for the most recent data of this field. Hit apply to make sure
          that the selected data will be attached to the record.</li>

          <li>Data is displayed in 'Kg/ha'</li>
        </ul>
      </div>


      <div v-if="dictionaryContent == 'crop_form'">
        <ul>
          <li><b>Standard marketable yield:</b> The expected yield can be adjusted for every record created.</li>

          <li><b>Some other thing:</b> More explanation...</li>
        </ul>
      </div>
      </div>


      <div v-if="dictionaryContent == 'n_recommendation'">
        <ul>
          <li><b>N recommendation calculation method:</b>Explanation...</li>

          <li><b>Some other thing:</b> More explanation...</li>
        </ul>
      </div>

      <ul class="option__group">
        <li><button type="button" name="button" @click="$store.commit('SET_DICTIONARY_CONTENT', null)">Close</button></li>
      </ul>
    </section>
  </aside>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'dictionaryContent',
    ]),
  }
}
</script>
